import { useState, useEffect } from 'react';
import { onAuthStateChanged, type User } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { signIn, signUp, signOut, type AuthError } from '../lib/firebase/auth';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async (email: string, password: string) => {
    try {
      const result = await signIn(email, password);
      return { user: result.user };
    } catch (error) {
      throw error as AuthError;
    }
  };

  const handleSignUp = async (email: string, password: string, pseudo: string) => {
    try {
      const result = await signUp(email, password, pseudo);
      return { user: result.user };
    } catch (error) {
      throw error as AuthError;
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      throw error as AuthError;
    }
  };

  return {
    user,
    loading,
    signIn: handleSignIn,
    signUp: handleSignUp,
    signOut: handleSignOut
  };
}