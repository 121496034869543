import { useState } from 'react';
import { stripeApi, StripeApiError } from '../lib/stripe/api';
import type { CreateAccountData } from '../lib/stripe/types';

export function useStripeAccount() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const createAccount = async (data: CreateAccountData): Promise<string> => {
    setLoading(true);
    setError(null);

    try {
      const accountId = await stripeApi.createAccount(data);
      return accountId;
    } catch (err) {
      let errorMessage = 'Failed to create account';
      
      if (err instanceof StripeApiError) {
        errorMessage = err.message;
      } else if (err instanceof Error) {
        errorMessage = err.message;
      }

      setError(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    createAccount,
    loading,
    error
  };
}