import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { MediaItem } from '../types/media';
import { Lock } from 'lucide-react';

export function MediaView() {
  const { id } = useParams();
  const [media, setMedia] = useState<MediaItem | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMedia = async () => {
      if (!id) return;

      try {
        const mediaDoc = await getDoc(doc(db, 'media', id));
        if (mediaDoc.exists()) {
          setMedia({ id: mediaDoc.id, ...mediaDoc.data() } as MediaItem);
        }
      } catch (error) {
        console.error('Error fetching media:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMedia();
  }, [id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!media) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-600">Contenu non trouvé</p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="aspect-video relative">
          <img
            src={media.url}
            alt={media.title}
            className={`w-full h-full object-cover ${media.isLocked ? 'blur-xl' : ''}`}
          />
          {media.isLocked && (
            <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
              <div className="text-center p-4">
                <Lock className="w-12 h-12 text-white mx-auto mb-4" />
                <h2 className="text-white text-xl font-bold mb-4">
                  Contenu Premium
                </h2>
                <button className="bg-white text-indigo-600 px-6 py-3 rounded-lg font-medium hover:bg-indigo-50 transition-colors">
                  Débloquer pour {media.price}€
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="p-6">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">{media.title}</h1>
          <p className="text-gray-600">
            Prix: {media.price}€
          </p>
        </div>
      </div>
    </div>
  );
}