import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { SignupForm } from '../components/auth/SignupForm';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { getAuthErrorMessage } from '../utils/errorMessages';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface SignupFormData {
  pseudo: string;
  email: string;
  password: string;
}

export function SignupPage() {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (formData: SignupFormData) => {
    setError('');
    setLoading(true);

    try {
      // Create user with Firebase Auth
      const userCredential = await signUp(
        formData.email,
        formData.password,
        formData.pseudo
      );

      if (!userCredential?.user) {
        throw new Error(t('auth.user_creation_failed'));
      }

      // Create user document in Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        pseudo: formData.pseudo,
        email: formData.email,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      // Navigate to profile page
      navigate('/profile');
    } catch (err: any) {
      console.error('Registration error:', err);
      setError(getAuthErrorMessage(err.code || 'auth/unknown', t));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-xl overflow-hidden"
        >
          <div className="px-6 py-8">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-900">
                {t('auth.create_account')}
              </h2>
              <p className="mt-2 text-gray-600">
                {t('auth.signup_description')}
              </p>
            </div>

            {error && (
              <div className="rounded-lg bg-red-50 p-4 mb-6">
                <div className="flex items-center">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <p className="ml-3 text-sm text-red-700">{error}</p>
                </div>
              </div>
            )}

            <SignupForm onSubmit={handleSubmit} loading={loading} />
          </div>
        </motion.div>
      </div>
    </div>
  );
}