import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { AuthForm } from '../components/auth/AuthForm';
import { getAuthErrorMessage } from '../utils/errorMessages';

export function Login() {
  const { t } = useTranslation();
  const { signIn, signUp, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsSignUp(searchParams.get('signup') === 'true');
  }, [location]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignUp) {
        if (!username.trim()) {
          setError(t('username_required'));
          return;
        }
        await signUp(email, password, username);
      } else {
        await signIn(email, password);
      }
      navigate('/profile');
    } catch (err: any) {
      setError(getAuthErrorMessage(err.code, t));
    }
  };

  // If user is already logged in, redirect to profile
  if (user) {
    return <Navigate to="/profile" />;
  }

  return (
    <AuthForm
      isSignUp={isSignUp}
      email={email}
      password={password}
      username={username}
      error={error}
      onEmailChange={setEmail}
      onPasswordChange={setPassword}
      onUsernameChange={setUsername}
      onSubmit={handleSubmit}
      onToggleMode={() => setIsSignUp(!isSignUp)}
    />
  );
}