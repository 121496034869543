import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatedText } from '../AnimatedText';

interface AuthFormProps {
  isSignUp: boolean;
  email: string;
  password: string;
  username: string;
  error: string;
  onEmailChange: (email: string) => void;
  onPasswordChange: (password: string) => void;
  onUsernameChange: (username: string) => void;
  onSubmit: (e: React.FormEvent) => void;
  onToggleMode: () => void;
}

export function AuthForm({
  isSignUp,
  email,
  password,
  username,
  error,
  onEmailChange,
  onPasswordChange,
  onUsernameChange,
  onSubmit,
  onToggleMode
}: AuthFormProps) {
  const { t } = useTranslation();

  return (
    <div className="max-w-md mx-auto">
      <AnimatedText
        text={isSignUp ? t('auth.signup') : t('auth.login')}
        className="text-3xl font-bold mb-8 text-center"
        gradient
      />

      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      <form onSubmit={onSubmit} className="space-y-6">
        {isSignUp && (
          <div>
            <AnimatedText
              text={t('auth.username')}
              className="block text-sm font-medium text-gray-700 mb-1"
              hover
            />
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => onUsernameChange(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-300 focus:ring-indigo-200 focus:ring-opacity-50"
              required
              minLength={3}
              maxLength={30}
            />
          </div>
        )}

        <div>
          <AnimatedText
            text={t('auth.email')}
            className="block text-sm font-medium text-gray-700 mb-1"
            hover
          />
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => onEmailChange(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-300 focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>

        <div>
          <AnimatedText
            text={t('auth.password')}
            className="block text-sm font-medium text-gray-700 mb-1"
            hover
          />
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => onPasswordChange(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-300 focus:ring-indigo-200 focus:ring-opacity-50"
            required
            minLength={6}
          />
        </div>

        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-indigo-400 to-purple-400 hover:from-indigo-500 hover:to-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 transition-all duration-300 hover:scale-[1.02]"
        >
          {isSignUp ? t('auth.signup') : t('auth.login')}
        </button>
      </form>

      {isSignUp && (
        <div className="mt-4 text-center text-sm text-gray-600">
          <AnimatedText
            text={t('auth.already_account')}
            className="inline-block"
            hover
          />
          {' '}
          <button
            onClick={onToggleMode}
            className="text-indigo-400 hover:text-purple-400 transition-colors duration-300"
          >
            {t('auth.login')}
          </button>
        </div>
      )}
    </div>
  );
}