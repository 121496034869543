import React, { useState, useEffect } from 'react';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { AnimatedText } from '../AnimatedText';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../hooks/useAuth';
import { CreateProfileModal } from './CreateProfileModal';
import { QuickUploadModal } from './QuickUploadModal';

interface HeroSectionProps {
  onGetStarted: () => void;
}

export function HeroSection({ onGetStarted }: HeroSectionProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [hasProfiles, setHasProfiles] = useState(false);
  const [showCreateProfile, setShowCreateProfile] = useState(false);
  const [showQuickUpload, setShowQuickUpload] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkProfiles = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        const profileQuery = query(
          collection(db, 'modelProfiles'),
          where('userId', '==', user.uid)
        );
        const profileSnapshot = await getDocs(profileQuery);
        const hasProfs = !profileSnapshot.empty;
        setHasProfiles(hasProfs);
        
        if (hasProfs) {
          setSelectedModelId(profileSnapshot.docs[0].id);
        }
      } catch (error) {
        console.error('Error checking profiles:', error);
      } finally {
        setLoading(false);
      }
    };

    checkProfiles();
  }, [user]);

  const handleGetStarted = async () => {
    if (!user) {
      onGetStarted();
      return;
    }

    if (hasProfiles) {
      setShowQuickUpload(true);
    } else {
      setShowCreateProfile(true);
    }
  };

  return (
    <div className="relative min-h-[80vh] flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0 bg-white">
        <div className="absolute inset-0 bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 opacity-30" />
      </div>

      <div className="relative z-10 max-w-4xl mx-auto px-4 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-12"
        >
          <motion.svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <path
              d="M60 10L105.98 35V85L60 110L14.0192 85V35L60 10Z"
              className="fill-[url(#gradient-hero)]"
            />
            <path
              d="M60 30L85.98 45V75L60 90L34.0192 75V45L60 30Z"
              fill="white"
              fillOpacity="0.9"
            />
            <circle cx="60" cy="60" r="8" className="fill-[url(#gradient-hero)]" />
            <defs>
              <linearGradient
                id="gradient-hero"
                x1="0"
                y1="0"
                x2="120"
                y2="120"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0%" stopColor="#4F46E5" />
                <stop offset="100%" stopColor="#7C3AED" />
              </linearGradient>
            </defs>
          </motion.svg>
        </motion.div>

        <AnimatedText
          text={t('hero_title')}
          className="text-6xl font-extrabold bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 bg-clip-text text-transparent mb-6 leading-tight"
          delay={0.2}
        />

        <AnimatedText
          text={t('hero_subtitle')}
          className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto"
          delay={0.4}
        />

        <motion.button
          onClick={handleGetStarted}
          className="group relative inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-indigo-400 to-purple-400 text-white rounded-full text-lg font-semibold overflow-hidden transition-all duration-300 hover:from-indigo-500 hover:to-purple-500 hover:scale-105 border border-transparent hover:border-indigo-200"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={loading}
        >
          {loading ? (
            <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
          ) : (
            <>
              <span>{hasProfiles ? t('liens_express') : t('start_sharing')}</span>
              <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
            </>
          )}
        </motion.button>
      </div>

      <CreateProfileModal
        isOpen={showCreateProfile}
        onClose={() => setShowCreateProfile(false)}
        onProfileCreated={() => {
          setHasProfiles(true);
          setShowCreateProfile(false);
          onGetStarted();
        }}
      />

      {selectedModelId && (
        <QuickUploadModal
          isOpen={showQuickUpload}
          onClose={() => setShowQuickUpload(false)}
          modelId={selectedModelId}
        />
      )}
    </div>
  );
}