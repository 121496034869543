import React from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface ValidationMessageProps {
  message: string | null;
  type: 'error' | 'success';
}

export function ValidationMessage({ message, type }: ValidationMessageProps) {
  if (!message) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        className={`flex items-center gap-2 mt-1 text-sm ${
          type === 'error' ? 'text-red-600' : 'text-green-600'
        }`}
      >
        {type === 'error' ? (
          <AlertCircle className="w-4 h-4" />
        ) : (
          <CheckCircle className="w-4 h-4" />
        )}
        <span>{message}</span>
      </motion.div>
    </AnimatePresence>
  );
}