import React from 'react';
import { CreditCard, AlertCircle, CheckCircle } from 'lucide-react';
import { AccountStatus } from '../../types/stripe';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

interface WalletOverviewProps {
  accountStatus: AccountStatus;
  stripeAccountId: string;
}

export function WalletOverview({ accountStatus, stripeAccountId }: WalletOverviewProps) {
  const { t } = useTranslation();

  const isFullyVerified = 
    accountStatus.payouts_enabled && 
    accountStatus.charges_enabled &&
    accountStatus.requirements.currently_due.length === 0;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-sm overflow-hidden"
    >
      <div className="p-6">
        <div className="flex items-center gap-4 mb-6">
          <div className="p-3 bg-indigo-50 rounded-lg">
            <CreditCard className="w-6 h-6 text-indigo-600" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900">
              {t('wallet.account_status')}
            </h2>
            <p className="text-sm text-gray-500">
              {t('wallet.account_id')}: {stripeAccountId}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              {accountStatus.charges_enabled ? (
                <CheckCircle className="w-5 h-5 text-green-500" />
              ) : (
                <AlertCircle className="w-5 h-5 text-yellow-500" />
              )}
              <span className="font-medium text-gray-900">
                {t('wallet.payments_status')}
              </span>
            </div>
            <p className="text-sm text-gray-600">
              {accountStatus.charges_enabled
                ? t('wallet.payments_enabled')
                : t('wallet.payments_disabled')}
            </p>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              {accountStatus.payouts_enabled ? (
                <CheckCircle className="w-5 h-5 text-green-500" />
              ) : (
                <AlertCircle className="w-5 h-5 text-yellow-500" />
              )}
              <span className="font-medium text-gray-900">
                {t('wallet.payouts_status')}
              </span>
            </div>
            <p className="text-sm text-gray-600">
              {accountStatus.payouts_enabled
                ? t('wallet.payouts_enabled')
                : t('wallet.payouts_disabled')}
            </p>
          </div>
        </div>

        {!isFullyVerified && (
          <div className="mt-6 p-4 bg-yellow-50 rounded-lg">
            <div className="flex items-start gap-3">
              <AlertCircle className="w-5 h-5 text-yellow-500 mt-0.5" />
              <div>
                <h3 className="font-medium text-yellow-800">
                  {t('wallet.verification_required')}
                </h3>
                <p className="text-sm text-yellow-700 mt-1">
                  {t('wallet.complete_verification')}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}