import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  updateProfile,
  type UserCredential
} from 'firebase/auth';
import { auth } from './core';

export interface AuthError {
  code: string;
  message: string;
}

export const signIn = async (
  email: string,
  password: string
): Promise<UserCredential> => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (error: any) {
    throw {
      code: error.code,
      message: error.message
    } as AuthError;
  }
};

export const signUp = async (
  email: string,
  password: string,
  pseudo: string
): Promise<UserCredential> => {
  try {
    const credential = await createUserWithEmailAndPassword(auth, email, password);
    
    if (credential.user) {
      await updateProfile(credential.user, { displayName: pseudo });
    }
    
    return credential;
  } catch (error: any) {
    throw {
      code: error.code,
      message: error.message
    } as AuthError;
  }
};

export const signOut = async (): Promise<void> => {
  try {
    await firebaseSignOut(auth);
  } catch (error: any) {
    throw {
      code: error.code,
      message: error.message
    } as AuthError;
  }
};