import React, { useRef, useState } from 'react';
import { Upload } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { storage, db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { MediaItem } from '../types/media';

interface UploadButtonProps {
  modelId: string;
  onUploadComplete: (media: MediaItem) => void;
}

export function UploadButton({ modelId, onUploadComplete }: UploadButtonProps) {
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { user } = useAuth();

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || !user) return;

    setUploading(true);
    try {
      for (const file of Array.from(files)) {
        const timestamp = Date.now();
        const filename = `${timestamp}-${file.name}`;
        const storageRef = ref(storage, `media/${user.uid}/${filename}`);
        
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        
        const mediaData = {
          userId: user.uid,
          modelId,
          title: file.name.replace(/\.[^/.]+$/, ''),
          url,
          price: 5.99,
          isLocked: true,
          isPublic: true,
          createdAt: new Date().toISOString(),
          type: file.type.startsWith('image/') ? 'image' : 'video',
          blurData: {
            isBlurred: false
          }
        };

        const mediaDoc = await addDoc(collection(db, 'media'), mediaData);
        onUploadComplete({
          id: mediaDoc.id,
          ...mediaData
        });
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept="image/*,video/*"
        multiple
        onChange={handleFileSelect}
      />
      <button
        onClick={() => fileInputRef.current?.click()}
        disabled={uploading}
        className={`flex items-center space-x-2 px-4 py-2 rounded-lg ${
          uploading
            ? 'text-gray-400 cursor-not-allowed'
            : 'bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent hover:from-indigo-700 hover:to-purple-700'
        } transition-all duration-300`}
      >
        {uploading ? (
          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-indigo-600"></div>
        ) : (
          <Upload className="w-5 h-5 text-indigo-600" />
        )}
        <span className="font-medium">{uploading ? t('uploading') : t('upload_button')}</span>
      </button>
    </>
  );
}