export default {
  auth: {
    login: 'Connexion',
    signup: "S'inscrire",
    email: 'Email',
    password: 'Mot de passe',
    username: "Nom d'utilisateur",
    create_account: 'Créer un compte',
    user_creation_failed: 'Échec de la création du compte',
    signup_description: 'Commencez à vendre votre contenu premium',
    already_account: 'Déjà un compte ?',
    no_account: 'Pas encore de compte ?'
  },
  errors: {
    auth: {
      'auth/email-already-in-use': 'Cet email est déjà utilisé',
      'auth/invalid-email': 'Email invalide',
      'auth/weak-password': 'Le mot de passe doit contenir au moins 6 caractères',
      'auth/wrong-password': 'Mot de passe incorrect',
      'auth/user-not-found': 'Aucun compte trouvé avec cet email',
      'auth/unknown': 'Une erreur est survenue'
    }
  },
  common: {
    loading: 'Chargement...',
    error: 'Une erreur est survenue',
    success: 'Succès !',
    cancel: 'Annuler',
    save: 'Enregistrer',
    delete: 'Supprimer',
    edit: 'Modifier',
    confirm: 'Confirmer'
  }
};