import React, { useState, useEffect } from 'react';
import { EyeOff, Eye, Trash2, Edit2, Globe, Save } from 'lucide-react';
import { MediaItem } from '../types/media';
import { useTranslation } from 'react-i18next';
import { ShareMediaButton } from './ShareMediaButton';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { motion } from 'framer-motion';

interface MediaGridProps {
  items: MediaItem[];
  onPurchase: (id: string) => void;
  showManageButtons?: boolean;
  onDelete?: (id: string) => void;
  onEdit?: (id: string, updates: Partial<MediaItem>) => void;
  onToggleVisibility?: (id: string) => void;
  isSharedView?: boolean;
  isPublicLibrary?: boolean;
}

export function MediaGrid({ 
  items, 
  onPurchase, 
  showManageButtons = false,
  onDelete,
  onEdit,
  onToggleVisibility,
  isSharedView = false,
  isPublicLibrary = true
}: MediaGridProps) {
  const { t } = useTranslation();
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editTitle, setEditTitle] = useState('');
  const [editPrice, setEditPrice] = useState<number>(0);
  const [blurredItems, setBlurredItems] = useState<Set<string>>(new Set());

  useEffect(() => {
    const initialBlurredItems = new Set(
      items
        .filter(item => item.blurData?.isBlurred)
        .map(item => item.id)
    );
    setBlurredItems(initialBlurredItems);
  }, [items]);

  const buttonBaseClasses = "flex-1 flex items-center justify-center gap-1 text-xs transition-colors py-2 px-3 rounded-lg";
  const primaryButtonClasses = `${buttonBaseClasses} text-indigo-400 hover:text-indigo-500 border border-indigo-100 hover:border-indigo-200 bg-indigo-50/50 hover:bg-indigo-100/50`;
  const secondaryButtonClasses = `${buttonBaseClasses} text-blue-400 hover:text-blue-500 border border-blue-100 hover:border-blue-200 bg-blue-50/50 hover:bg-blue-100/50`;
  const dangerButtonClasses = `${buttonBaseClasses} text-red-400 hover:text-red-500 border border-red-100 hover:border-red-200 bg-red-50/50 hover:bg-red-100/50`;

  const handleStartEdit = (item: MediaItem) => {
    setEditingId(item.id);
    setEditTitle(item.title);
    setEditPrice(item.price);
  };

  const handleSaveEdit = async (id: string) => {
    if (onEdit) {
      await onEdit(id, {
        title: editTitle,
        price: editPrice
      });
    }
    setEditingId(null);
  };

  const toggleBlur = async (id: string) => {
    try {
      const isBlurred = !blurredItems.has(id);
      const mediaRef = doc(db, 'media', id);
      await updateDoc(mediaRef, {
        blurData: {
          isBlurred,
          updatedAt: new Date().toISOString()
        }
      });

      setBlurredItems(prev => {
        const newSet = new Set(prev);
        if (isBlurred) {
          newSet.add(id);
        } else {
          newSet.delete(id);
        }
        return newSet;
      });
    } catch (error) {
      console.error('Error updating blur state:', error);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {items.map((item, index) => (
        <motion.div
          key={item.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="group relative bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="aspect-square relative overflow-hidden">
            {item.type === 'image' ? (
              <img
                src={item.url}
                alt={item.title}
                className={`w-full h-full object-cover transition-all duration-300 ${
                  blurredItems.has(item.id) ? 'blur-lg' : ''
                }`}
              />
            ) : (
              <video
                src={item.url}
                className={`w-full h-full object-cover transition-all duration-300 ${
                  blurredItems.has(item.id) ? 'blur-lg' : ''
                }`}
                controls={!blurredItems.has(item.id)}
              />
            )}

            {showManageButtons && (
              <button
                onClick={() => toggleBlur(item.id)}
                className="absolute top-2 right-2 p-2 bg-white/80 rounded-full shadow-lg hover:bg-white transition-colors"
                title={blurredItems.has(item.id) ? t('unblur') : t('blur')}
              >
                {blurredItems.has(item.id) ? (
                  <Eye className="w-4 h-4 text-indigo-600" />
                ) : (
                  <EyeOff className="w-4 h-4 text-indigo-600" />
                )}
              </button>
            )}
          </div>

          <div className="p-4">
            {editingId === item.id ? (
              <div className="space-y-3">
                <input
                  type="text"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                />
                <input
                  type="number"
                  value={editPrice}
                  onChange={(e) => setEditPrice(Number(e.target.value))}
                  step="0.01"
                  min="0.99"
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                />
                <div className="flex gap-2">
                  <button
                    onClick={() => handleSaveEdit(item.id)}
                    className={primaryButtonClasses}
                  >
                    <Save className="w-3 h-3" />
                    {t('save')}
                  </button>
                  <button
                    onClick={() => setEditingId(null)}
                    className={dangerButtonClasses}
                  >
                    {t('cancel')}
                  </button>
                </div>
              </div>
            ) : (
              <>
                <h3 className="font-medium text-gray-900 mb-1">{item.title}</h3>
                {!isSharedView && blurredItems.has(item.id) && (
                  <p className="text-sm text-gray-500">{item.price}€</p>
                )}

                {showManageButtons && (
                  <div className="grid grid-cols-2 gap-2 mt-3">
                    {blurredItems.has(item.id) && (
                      <ShareMediaButton
                        media={item}
                        className={primaryButtonClasses}
                      />
                    )}
                    <button
                      onClick={() => handleStartEdit(item)}
                      className={primaryButtonClasses}
                    >
                      <Edit2 className="w-3 h-3" />
                      {t('edit')}
                    </button>
                    {onToggleVisibility && (
                      <button
                        onClick={() => onToggleVisibility(item.id)}
                        className={secondaryButtonClasses}
                      >
                        {item.isPublic ? (
                          <>
                            <EyeOff className="w-3 h-3" />
                            {t('make_private')}
                          </>
                        ) : (
                          <>
                            <Globe className="w-3 h-3" />
                            {t('make_public')}
                          </>
                        )}
                      </button>
                    )}
                    {onDelete && (
                      <button
                        onClick={() => onDelete(item.id)}
                        className={dangerButtonClasses}
                      >
                        <Trash2 className="w-3 h-3" />
                        {t('delete')}
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </motion.div>
      ))}
    </div>
  );
}