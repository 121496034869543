export default {
  auth: {
    login: 'Login',
    signup: 'Sign Up',
    email: 'Email',
    password: 'Password',
    username: 'Username',
    create_account: 'Create Account',
    user_creation_failed: 'Failed to create account',
    signup_description: 'Start selling your premium content',
    already_account: 'Already have an account?',
    no_account: "Don't have an account?"
  },
  errors: {
    auth: {
      'auth/email-already-in-use': 'This email is already in use',
      'auth/invalid-email': 'Invalid email',
      'auth/weak-password': 'Password must be at least 6 characters',
      'auth/wrong-password': 'Incorrect password',
      'auth/user-not-found': 'No account found with this email',
      'auth/unknown': 'An error occurred'
    }
  },
  common: {
    loading: 'Loading...',
    error: 'An error occurred',
    success: 'Success!',
    cancel: 'Cancel',
    save: 'Save',
    delete: 'Delete',
    edit: 'Edit',
    confirm: 'Confirm'
  }
};