import React from 'react';
import { X, ScrollText } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function TermsModal({ isOpen, onClose }: TermsModalProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity"
              onClick={onClose}
            />

            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              className="relative inline-block w-full max-w-4xl p-6 my-8 text-left bg-white/90 backdrop-blur-sm rounded-2xl shadow-xl transform transition-all"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  <ScrollText className="w-5 h-5 text-indigo-600" />
                  <h3 className="text-lg font-semibold text-gray-900">
                    Conditions Générales d'Utilisation (CGU) – ClearMeHub
                  </h3>
                </div>
                <button
                  onClick={onClose}
                  className="text-gray-400 hover:text-gray-500 transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="mt-4 max-h-[70vh] overflow-y-auto pr-4 space-y-6 text-gray-600 custom-scrollbar">
                <p className="text-sm text-gray-500">
                  Les présentes Conditions Générales d'Utilisation (CGU) régissent l'accès et l'utilisation de la plateforme ClearMeHub.
                  En accédant à notre site, vous acceptez sans réserve les présentes CGU. Si vous refusez ces conditions, nous vous prions de ne pas utiliser nos services.
                </p>

                <section className="space-y-4">
                  <h4 className="text-lg font-semibold text-gray-900">1. Objet de la Plateforme</h4>
                  <p>ClearMeHub permet aux utilisateurs :</p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>D'importer leurs photos ;</li>
                    <li>De les modifier via un outil de floutage ;</li>
                    <li>De partager ou vendre ces photos au prix de leur choix ;</li>
                    <li>De rendre les photos non floutées disponibles uniquement après leur achat par un utilisateur.</li>
                  </ul>
                </section>

                <section className="space-y-4">
                  <h4 className="text-lg font-semibold text-gray-900">2. Utilisation Conforme à la Réglementation Française</h4>
                  <div className="space-y-4">
                    <h5 className="font-medium text-gray-800">2.1 Respect des Lois</h5>
                    <p>ClearMeHub respecte la législation française, notamment :</p>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>Les articles L.111-1 et suivants du Code de la propriété intellectuelle relatifs au droit d'auteur ;</li>
                      <li>Les dispositions du Règlement Général sur la Protection des Données (RGPD) et la loi "Informatique et Libertés".</li>
                    </ul>
                    
                    <h5 className="font-medium text-gray-800">2.2 Contenus Prohibés</h5>
                    <p>Il est strictement interdit d'importer, vendre ou partager des contenus :</p>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>En violation des droits d'auteur, du droit à l'image ou de la vie privée ;</li>
                      <li>À caractère illégal, diffamatoire, haineux, violent, pornographique ou contraire aux bonnes mœurs ;</li>
                      <li>Susceptibles de nuire à l'ordre public ou aux droits d'autrui.</li>
                    </ul>
                  </div>
                </section>

                <section className="space-y-4">
                  <h4 className="text-lg font-semibold text-gray-900">3. Conditions d'Inscription et de Comptes Utilisateurs</h4>
                  <div className="space-y-4">
                    <h5 className="font-medium text-gray-800">3.1 Création de Compte</h5>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>L'inscription est réservée aux personnes majeures (18 ans révolus).</li>
                      <li>Les informations fournies lors de l'inscription doivent être exactes, complètes et mises à jour régulièrement.</li>
                    </ul>

                    <h5 className="font-medium text-gray-800">3.2 Sécurité des Comptes</h5>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>Les utilisateurs sont responsables de la sécurité de leurs identifiants et de toutes les activités effectuées via leur compte.</li>
                      <li>Toute utilisation non autorisée doit être signalée immédiatement à assistance@clearmehub.com.</li>
                    </ul>
                  </div>
                </section>

                <section className="space-y-4">
                  <h4 className="text-lg font-semibold text-gray-900">4. Droits sur les Contenus</h4>
                  <div className="space-y-4">
                    <h5 className="font-medium text-gray-800">4.1 Propriété des Utilisateurs</h5>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>Les utilisateurs conservent l'intégralité des droits sur les photos qu'ils importent.</li>
                      <li>En téléchargeant des contenus sur ClearMeHub, vous accordez une licence non exclusive à ClearMeHub pour afficher, flouter et commercialiser vos photos conformément à vos paramètres de vente.</li>
                    </ul>
                  </div>
                </section>

                <section className="space-y-4">
                  <h4 className="text-lg font-semibold text-gray-900">5. Modalités de Vente et Paiements</h4>
                  <div className="space-y-4">
                    <h5 className="font-medium text-gray-800">5.1 Prix des Photos</h5>
                    <p>Les utilisateurs définissent librement le prix de vente de leurs photos sur ClearMeHub.</p>

                    <h5 className="font-medium text-gray-800">5.2 Commissions et Revenus</h5>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>ClearMeHub applique une commission de 12,5 % sur chaque transaction effectuée via la plateforme.</li>
                      <li>Les revenus générés par les utilisateurs seront versés après déduction de cette commission.</li>
                    </ul>
                  </div>
                </section>

                <section className="space-y-4">
                  <h4 className="text-lg font-semibold text-gray-900">6. Protection des Données Personnelles</h4>
                  <p>ClearMeHub respecte les dispositions du RGPD et s'engage à protéger vos données personnelles.</p>
                  <div className="space-y-4">
                    <h5 className="font-medium text-gray-800">6.1 Vos Droits</h5>
                    <p>Vous disposez des droits suivants sur vos données personnelles :</p>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>Accès</li>
                      <li>Rectification</li>
                      <li>Suppression</li>
                      <li>Opposition</li>
                    </ul>
                  </div>
                </section>

                <section className="space-y-4">
                  <h4 className="text-lg font-semibold text-gray-900">7. Contact</h4>
                  <p>Pour toute question ou réclamation, vous pouvez nous contacter :</p>
                  <p className="font-medium">Email : assistance@clearmehub.com</p>
                </section>

                <p className="text-sm text-gray-500 italic">
                  En utilisant ClearMeHub, vous reconnaissez avoir lu, compris et accepté ces conditions générales d'utilisation.
                </p>
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-white bg-indigo-600/80 rounded-lg hover:bg-indigo-700/80 transition-colors backdrop-blur-sm"
                >
                  J'ai compris
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
}