import React from 'react';
import { useTranslation } from 'react-i18next';
import { Lock, Globe } from 'lucide-react';
import { AnimatedText } from './AnimatedText';

interface LibraryTabsProps {
  activeTab: 'public' | 'private';
  onTabChange: (tab: 'public' | 'private') => void;
  publicCount: number;
  privateCount: number;
}

export function LibraryTabs({ activeTab, onTabChange, publicCount, privateCount }: LibraryTabsProps) {
  const { t } = useTranslation();

  return (
    <div className="flex space-x-4">
      <button
        onClick={() => onTabChange('public')}
        className={`flex items-center px-3 py-1.5 rounded-lg transition-all duration-300 ${
          activeTab === 'public'
            ? 'bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent'
            : 'text-gray-600 hover:bg-gradient-to-r hover:from-indigo-600 hover:to-purple-600 hover:bg-clip-text hover:text-transparent'
        }`}
      >
        <Globe className={`w-4 h-4 mr-1.5 ${activeTab === 'public' ? 'text-indigo-600' : 'text-gray-400'}`} />
        <span className="font-medium">{t('public_library')}</span>
        <span className={`ml-1.5 px-1.5 py-0.5 rounded-full text-xs ${
          activeTab === 'public'
            ? 'text-indigo-600 bg-indigo-50'
            : 'text-gray-500 bg-gray-100'
        }`}>
          {publicCount}
        </span>
      </button>
      
      <button
        onClick={() => onTabChange('private')}
        className={`flex items-center px-3 py-1.5 rounded-lg transition-all duration-300 ${
          activeTab === 'private'
            ? 'bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent'
            : 'text-gray-600 hover:bg-gradient-to-r hover:from-indigo-600 hover:to-purple-600 hover:bg-clip-text hover:text-transparent'
        }`}
      >
        <Lock className={`w-4 h-4 mr-1.5 ${activeTab === 'private' ? 'text-indigo-600' : 'text-gray-400'}`} />
        <span className="font-medium">{t('private_library')}</span>
        <span className={`ml-1.5 px-1.5 py-0.5 rounded-full text-xs ${
          activeTab === 'private'
            ? 'text-indigo-600 bg-indigo-50'
            : 'text-gray-500 bg-gray-100'
        }`}>
          {privateCount}
        </span>
      </button>
    </div>
  );
}