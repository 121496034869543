import React, { useState } from 'react';
import { Share2, Check, ExternalLink } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { MediaItem } from '../types/media';

interface ShareMediaButtonProps {
  media: MediaItem;
  className?: string;
}

export function ShareMediaButton({ media, className = '' }: ShareMediaButtonProps) {
  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();

  const handleShare = async () => {
    // Generate a unique payment link with media ID and price
    const shareUrl = `${window.location.origin}/payment/${media.id}?price=${media.price}`;
    
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setShowTooltip(true);
      
      // Hide tooltip after 2 seconds
      setTimeout(() => {
        setCopied(false);
        setShowTooltip(false);
      }, 2000);
    } catch (err) {
      console.error('Error copying link:', err);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={handleShare}
        className={`inline-flex items-center justify-center gap-1.5 transition-all duration-200 ${className}`}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => !copied && setShowTooltip(false)}
      >
        {copied ? (
          <>
            <Check className="w-3 h-3" />
            <span>{t('link_copied')}</span>
          </>
        ) : (
          <>
            <Share2 className="w-3 h-3" />
            <span>{t('share')}</span>
          </>
        )}
      </button>

      {showTooltip && !copied && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-900 rounded whitespace-nowrap">
          {t('click_to_copy')}
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900"></div>
        </div>
      )}
    </div>
  );
}