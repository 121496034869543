import React, { useState, useRef } from 'react';
import { Camera, PlusCircle, Pencil, Trash2, Wallet } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ModelProfile } from '../types/media';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { AnimatedText } from './AnimatedText';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

interface ModelProfilesSectionProps {
  profiles: ModelProfile[];
  selectedProfileId: string | null;
  onSelectProfile: (id: string) => void;
  onCreateProfile: (data: { name: string }) => void;
  onUpdateProfile: (id: string, updates: Partial<ModelProfile>) => void;
  onDeleteProfile: (id: string) => void;
}

export function ModelProfilesSection({
  profiles,
  selectedProfileId,
  onSelectProfile,
  onCreateProfile,
  onUpdateProfile,
  onDeleteProfile
}: ModelProfilesSectionProps) {
  const [isCreating, setIsCreating] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [newProfileName, setNewProfileName] = useState('');
  const [editName, setEditName] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const { t } = useTranslation();

  const handleCreateSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newProfileName.trim()) {
      onCreateProfile({ name: newProfileName.trim() });
      setNewProfileName('');
      setIsCreating(false);
    }
  };

  const handleEditSubmit = (id: string) => {
    if (editName.trim()) {
      onUpdateProfile(id, { name: editName.trim() });
      setEditingId(null);
      setEditName('');
    }
  };

  const handleStartEdit = (e: React.MouseEvent, profile: ModelProfile) => {
    e.stopPropagation();
    setEditingId(profile.id);
    setEditName(profile.name);
  };

  const handleDelete = async (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    if (window.confirm(t('confirm_delete_model'))) {
      await onDeleteProfile(id);
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>, profileId: string) => {
    const file = event.target.files?.[0];
    if (!file || !user) return;

    try {
      const storageRef = ref(storage, `profiles/${user.uid}/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      onUpdateProfile(profileId, { profileImage: url });
    } catch (error) {
      console.error('Error uploading profile image:', error);
      alert(t('error_uploading_image'));
    }
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <AnimatedText
          text={t('your_media')}
          className="text-2xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent"
        />
        <Link
          to="/wallet"
          className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 bg-indigo-50 rounded-lg hover:bg-indigo-100 transition-colors"
        >
          <Wallet className="w-4 h-4" />
          {t('wallet.title')}
        </Link>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 sm:gap-8">
        {profiles.map((profile) => (
          <motion.div
            key={profile.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={`relative group cursor-pointer flex flex-col items-center ${
              selectedProfileId === profile.id
                ? 'scale-105'
                : ''
            }`}
            onClick={() => onSelectProfile(profile.id)}
          >
            <div className="w-full aspect-square relative">
              <div className={`w-full h-full rounded-full overflow-hidden ${
                selectedProfileId === profile.id ? 'ring-2 ring-indigo-500 ring-offset-2' : ''
              }`}>
                {profile.profileImage ? (
                  <img
                    src={profile.profileImage}
                    alt={profile.name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-indigo-50 to-purple-50">
                    <Camera className="w-12 h-12 text-indigo-300" />
                  </div>
                )}
              </div>
              
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, profile.id)}
              />
              
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  fileInputRef.current?.click();
                }}
                className="absolute inset-0 rounded-full flex items-center justify-center bg-black/0 group-hover:bg-black/20 opacity-0 group-hover:opacity-100 transition-all duration-200"
              >
                <span className="text-sm text-white font-medium">
                  {t('change_photo')}
                </span>
              </button>

              <div className="absolute -top-2 -right-2 flex gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                <button
                  onClick={(e) => handleStartEdit(e, profile)}
                  className="p-1.5 bg-white rounded-full shadow-md hover:bg-indigo-50 text-gray-600 hover:text-indigo-600 transition-colors"
                >
                  <Pencil className="w-4 h-4" />
                </button>
                <button
                  onClick={(e) => handleDelete(e, profile.id)}
                  className="p-1.5 bg-white rounded-full shadow-md hover:bg-red-50 text-gray-600 hover:text-red-600 transition-colors"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>
            
            {editingId === profile.id ? (
              <div className="mt-4 w-full" onClick={(e) => e.stopPropagation()}>
                <input
                  type="text"
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                  className="w-full text-sm text-center rounded-lg border-gray-300"
                  autoFocus
                />
                <div className="flex justify-center gap-2 mt-2">
                  <button
                    onClick={() => handleEditSubmit(profile.id)}
                    className="px-3 py-1 text-xs text-white bg-indigo-600 rounded-full hover:bg-indigo-700"
                  >
                    {t('save')}
                  </button>
                  <button
                    onClick={() => setEditingId(null)}
                    className="px-3 py-1 text-xs text-gray-600 bg-gray-100 rounded-full hover:bg-gray-200"
                  >
                    {t('cancel')}
                  </button>
                </div>
              </div>
            ) : (
              <p className="mt-4 text-sm font-medium text-gray-900 text-center">
                {profile.name}
              </p>
            )}
          </motion.div>
        ))}

        {/* Create new profile button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex flex-col items-center"
        >
          {isCreating ? (
            <form onSubmit={handleCreateSubmit} className="w-full text-center">
              <div className="w-full aspect-square mx-auto mb-4 rounded-full bg-gradient-to-br from-indigo-50 to-purple-50 flex items-center justify-center">
                <PlusCircle className="w-12 h-12 text-indigo-300" />
              </div>
              <input
                type="text"
                value={newProfileName}
                onChange={(e) => setNewProfileName(e.target.value)}
                className="w-full rounded-lg border-gray-300 text-sm text-center"
                placeholder={t('enter_profile_name')}
                autoFocus
              />
              <div className="flex gap-2 mt-2 justify-center">
                <button
                  type="submit"
                  className="px-3 py-1 text-xs text-white bg-indigo-600 rounded-full hover:bg-indigo-700"
                >
                  {t('create')}
                </button>
                <button
                  type="button"
                  onClick={() => setIsCreating(false)}
                  className="px-3 py-1 text-xs text-gray-600 bg-gray-100 rounded-full hover:bg-gray-200"
                >
                  {t('cancel')}
                </button>
              </div>
            </form>
          ) : (
            <button
              onClick={() => setIsCreating(true)}
              className="group flex flex-col items-center w-full"
            >
              <div className="w-full aspect-square rounded-full bg-gradient-to-br from-indigo-50/50 to-purple-50/50 group-hover:from-indigo-100/50 group-hover:to-purple-100/50 flex items-center justify-center transition-colors">
                <PlusCircle className="w-12 h-12 text-gray-400 group-hover:text-indigo-500 transition-colors" />
              </div>
              <span className="mt-4 text-sm font-medium text-gray-500 group-hover:text-indigo-600 transition-colors">
                {t('create_profile')}
              </span>
            </button>
          )}
        </motion.div>
      </div>
    </div>
  );
}