import React from 'react';
import { motion } from 'framer-motion';
import { ShoppingCart, CreditCard, ShieldCheck } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { MediaItem } from '../../types/media';

interface CartSummaryProps {
  selectedMedia: MediaItem[];
  onCheckout: () => void;
}

export function CartSummary({ selectedMedia, onCheckout }: CartSummaryProps) {
  const { t } = useTranslation();
  const total = selectedMedia
    .filter(media => media.blurData?.isBlurred)
    .reduce((sum, media) => sum + media.price, 0);

  const blurredMediaCount = selectedMedia.filter(media => media.blurData?.isBlurred).length;
  if (blurredMediaCount === 0) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="fixed bottom-0 inset-x-0 bg-white/95 backdrop-blur-sm border-t shadow-xl p-6 z-50"
    >
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="flex flex-col md:flex-row md:items-center gap-4">
            <div className="flex items-center gap-2">
              <ShoppingCart className="w-5 h-5 text-gray-600" />
              <span className="font-medium text-gray-900">
                {blurredMediaCount} {t('items_selected')}
              </span>
            </div>
            <div className="text-3xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
              {total}€
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-4">
            <div className="flex items-center gap-2 text-green-600">
              <ShieldCheck className="w-5 h-5" />
              <span className="text-sm font-medium">{t('secure_payment')}</span>
            </div>
            
            <motion.button
              onClick={onCheckout}
              className="w-full md:w-auto group relative flex items-center justify-center gap-2 px-8 py-4 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl font-semibold transition-all duration-300 hover:from-indigo-600 hover:to-purple-600 shadow-lg hover:shadow-xl hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <CreditCard className="w-5 h-5" />
              <span className="text-lg">{t('proceed_to_checkout')}</span>
              <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-indigo-600/20 to-purple-600/20 opacity-0 group-hover:opacity-100 transition-opacity" />
            </motion.button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}