import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { CreditCard, AlertCircle } from 'lucide-react';
import { useWalletSetup } from '../../hooks/useWalletSetup';
import { OnboardingForm } from './OnboardingForm';
import { StripeApiError } from '../../lib/stripe/api';

export function WalletSetupForm() {
  const [submitError, setSubmitError] = React.useState<string | null>(null);
  const { t } = useTranslation();
  const {
    formData,
    setFormData,
    errors,
    submitting,
    handleSubmit
  } = useWalletSetup();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitError(null);

    try {
      const result = await handleSubmit();
      if (!result.success) {
        setSubmitError(result.error || t('wallet.unknown_error'));
      } else {
        window.location.reload();
      }
    } catch (err) {
      console.error('Form submission error:', err);
      
      if (err instanceof StripeApiError) {
        setSubmitError(err.message);
      } else {
        setSubmitError(t('wallet.unknown_error'));
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-2xl mx-auto"
    >
      <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        <div className="p-6 bg-gradient-to-r from-indigo-50 to-purple-50">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-white rounded-lg">
              <CreditCard className="w-6 h-6 text-indigo-600" />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900">
                {t('wallet.setup_payments')}
              </h2>
              <p className="text-sm text-gray-600 mt-1">
                {t('wallet.setup_description')}
              </p>
            </div>
          </div>
        </div>

        {submitError && (
          <div className="px-6 pt-6">
            <div className="rounded-lg bg-red-50 p-4">
              <div className="flex items-center gap-3">
                <AlertCircle className="w-5 h-5 text-red-500" />
                <p className="text-sm text-red-700">{submitError}</p>
              </div>
            </div>
          </div>
        )}

        <div className="p-6">
          <OnboardingForm
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            submitting={submitting}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </motion.div>
  );
}