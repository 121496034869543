import React, { useState, useRef } from 'react';
import { X, Upload, Link, Copy, Check } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { storage, db } from '../../lib/firebase';
import { useAuth } from '../../hooks/useAuth';

interface QuickUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  modelId: string;
}

export function QuickUploadModal({ isOpen, onClose, modelId }: QuickUploadModalProps) {
  const [uploading, setUploading] = useState(false);
  const [price, setPrice] = useState(5.99);
  const [shareLink, setShareLink] = useState('');
  const [copied, setCopied] = useState(false);
  const [uploadedMedia, setUploadedMedia] = useState<{
    url: string;
    type: 'image' | 'video';
  } | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { user } = useAuth();

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || !user) return;

    setUploading(true);
    try {
      const file = files[0]; // On ne prend que le premier fichier pour l'upload rapide
      const timestamp = Date.now();
      const filename = `${timestamp}-${file.name}`;
      const storageRef = ref(storage, `media/${user.uid}/${filename}`);
      
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      
      const mediaData = {
        userId: user.uid,
        modelId,
        title: file.name.replace(/\.[^/.]+$/, ''),
        url,
        price,
        isLocked: true,
        isPublic: true,
        createdAt: new Date().toISOString(),
        type: file.type.startsWith('image/') ? 'image' : 'video',
        blurData: {
          isBlurred: true
        }
      };

      const mediaDoc = await addDoc(collection(db, 'media'), mediaData);
      
      // Générer le lien de partage
      const shareUrl = `${window.location.origin}/payment/${mediaDoc.id}?price=${price}`;
      setShareLink(shareUrl);
      setUploadedMedia({
        url,
        type: file.type.startsWith('image/') ? 'image' : 'video'
      });

    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Error copying link:', error);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm"
              onClick={onClose}
            />

            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              className="relative w-full max-w-md overflow-hidden rounded-xl bg-white shadow-xl"
            >
              <div className="px-6 py-4 border-b border-gray-200">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Upload className="w-5 h-5 text-indigo-600" />
                    <h3 className="text-lg font-semibold text-gray-900">
                      {t('quick_upload')}
                    </h3>
                  </div>
                  <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              </div>

              <div className="p-6 space-y-6">
                <input
                  ref={fileInputRef}
                  type="file"
                  className="hidden"
                  accept="image/*,video/*"
                  onChange={handleFileSelect}
                />

                {!shareLink && (
                  <>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        {t('price')}
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input
                          type="number"
                          value={price}
                          onChange={(e) => setPrice(Number(e.target.value))}
                          min="0.99"
                          step="0.01"
                          className="block w-full pl-7 pr-12 py-2 rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                    </div>

                    <button
                      onClick={() => fileInputRef.current?.click()}
                      disabled={uploading}
                      className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:from-indigo-600 hover:to-purple-600 transition-all duration-200 disabled:opacity-50"
                    >
                      {uploading ? (
                        <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                      ) : (
                        <>
                          <Upload className="w-5 h-5" />
                          {t('select_file')}
                        </>
                      )}
                    </button>
                  </>
                )}

                {shareLink && uploadedMedia && (
                  <div className="space-y-6">
                    <div className="aspect-square relative rounded-lg overflow-hidden">
                      {uploadedMedia.type === 'image' ? (
                        <img
                          src={uploadedMedia.url}
                          alt="Preview"
                          className="w-full h-full object-cover blur-lg"
                        />
                      ) : (
                        <video
                          src={uploadedMedia.url}
                          className="w-full h-full object-cover blur-lg"
                        />
                      )}
                      <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                        <div className="text-white text-center p-4">
                          <p className="font-medium mb-2">{t('preview_blurred')}</p>
                          <p className="text-sm opacity-80">{t('unlock_for')} {price}€</p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        {t('share_link')}
                      </label>
                      <div className="flex gap-2">
                        <input
                          type="text"
                          value={shareLink}
                          readOnly
                          className="block w-full rounded-l-lg border-gray-300 bg-gray-50"
                        />
                        <button
                          onClick={handleCopyLink}
                          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                        >
                          {copied ? (
                            <Check className="w-5 h-5" />
                          ) : (
                            <Copy className="w-5 h-5" />
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <button
                        onClick={onClose}
                        className="px-4 py-2 text-sm font-medium text-indigo-600 hover:bg-indigo-50 rounded-lg"
                      >
                        {t('done')}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
}