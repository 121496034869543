import React from 'react';
import { X } from 'lucide-react';
import { MediaItem } from '../../types/media';
import { motion, AnimatePresence } from 'framer-motion';

interface PreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  media: MediaItem | null;
}

export function PreviewModal({ isOpen, onClose, media }: PreviewModalProps) {
  if (!media) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-hidden">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-black/90 backdrop-blur-sm"
            onClick={onClose}
          />

          <div className="absolute inset-0 overflow-hidden">
            <div className="flex min-h-full items-center justify-center p-4">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className="relative max-w-5xl w-full"
              >
                <button
                  onClick={onClose}
                  className="absolute -top-12 right-0 p-2 text-white/80 hover:text-white"
                >
                  <X className="w-6 h-6" />
                </button>

                <div className="relative rounded-lg overflow-hidden bg-black/50 backdrop-blur-sm">
                  {media.type === 'image' ? (
                    <img
                      src={media.url}
                      alt={media.title}
                      className={`w-full h-auto ${media.blurData?.isBlurred ? 'blur-lg' : ''}`}
                    />
                  ) : (
                    <video
                      src={media.url}
                      className={`w-full h-auto ${media.blurData?.isBlurred ? 'blur-lg' : ''}`}
                      controls={!media.blurData?.isBlurred}
                    />
                  )}
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
}