export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePassword = (password: string): boolean => {
  // At least 8 characters, 1 uppercase, 1 lowercase, 1 number
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return passwordRegex.test(password);
};

export const validateIBAN = (iban: string): boolean => {
  // Basic IBAN format for France (FR + 27 characters)
  const ibanRegex = /^FR\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{3}$/;
  return ibanRegex.test(iban.replace(/\s/g, ''));
};

export const getValidationError = (field: string, value: string, t: (key: string) => string): string | null => {
  switch (field) {
    case 'email':
      return !validateEmail(value) ? t('invalid_email_format') : null;
    case 'password':
      return !validatePassword(value) ? t('invalid_password_format') : null;
    case 'iban':
      return !validateIBAN(value) ? t('invalid_iban_format') : null;
    default:
      return null;
  }
};