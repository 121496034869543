import React from 'react';
import { Upload, Globe, Lock, Share2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

interface MobileNavigationProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
  publicCount: number;
  privateCount: number;
}

export function MobileNavigation({ activeTab, onTabChange, publicCount, privateCount }: MobileNavigationProps) {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 'public',
      label: t('public_library'),
      icon: Globe,
      count: publicCount
    },
    {
      id: 'private',
      label: t('private_library'),
      icon: Lock,
      count: privateCount
    },
    {
      id: 'share',
      label: t('share_library'),
      icon: Share2
    },
    {
      id: 'upload',
      label: t('upload_button'),
      icon: Upload
    }
  ];

  return (
    <div className="portrait:flex lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg px-4 py-2 z-50">
      <div className="w-full grid grid-cols-4 gap-1">
        {tabs.map((tab) => (
          <motion.button
            key={tab.id}
            onClick={() => onTabChange(tab.id)}
            className={`flex flex-col items-center justify-center p-2 rounded-lg ${
              activeTab === tab.id
                ? 'text-indigo-600'
                : 'text-gray-500'
            }`}
            whileTap={{ scale: 0.95 }}
          >
            <tab.icon className="w-5 h-5" />
            <span className="text-xs mt-1">{tab.label}</span>
            {tab.count !== undefined && (
              <span className={`text-xs px-1.5 py-0.5 rounded-full ${
                activeTab === tab.id
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-100 text-gray-600'
              }`}>
                {tab.count}
              </span>
            )}
          </motion.button>
        ))}
      </div>
    </div>
  );
}