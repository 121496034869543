import React from 'react';
import { useStripeAccount } from '../../hooks/useStripeAccount';
import { AccountStatus } from '../../types/stripe';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { AlertCircle, CheckCircle, Clock } from 'lucide-react';

interface AccountRequirementsProps {
  requirements: AccountStatus['requirements'];
  stripeAccountId: string;
}

export function AccountRequirements({ requirements, stripeAccountId }: AccountRequirementsProps) {
  const { updateAccount, loading } = useStripeAccount();
  const { t } = useTranslation();

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64Data = reader.result?.toString().split(',')[1];
      if (base64Data) {
        try {
          await updateAccount(stripeAccountId, {
            verification_document: base64Data
          });
          window.location.reload();
        } catch (error) {
          console.error('Error uploading document:', error);
        }
      }
    };
    reader.readAsDataURL(file);
  };

  if (!requirements) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white rounded-xl shadow-sm p-6"
      >
        <div className="flex items-center gap-3">
          <Clock className="w-6 h-6 text-gray-400" />
          <p className="text-gray-600">
            {t('wallet.loading_requirements')}
          </p>
        </div>
      </motion.div>
    );
  }

  const currentlyDue = requirements.currently_due || [];
  const eventuallyDue = requirements.eventually_due || [];
  const pastDue = requirements.past_due || [];
  const pendingVerification = requirements.pending_verification || [];

  if (
    currentlyDue.length === 0 &&
    eventuallyDue.length === 0 &&
    pastDue.length === 0 &&
    pendingVerification.length === 0
  ) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-green-50 rounded-xl p-6"
      >
        <div className="flex items-center gap-3">
          <CheckCircle className="w-6 h-6 text-green-500" />
          <p className="text-green-700 font-medium">
            {t('wallet.verification_complete')}
          </p>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-sm overflow-hidden"
    >
      <div className="p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-6">
          {t('wallet.verification_requirements')}
        </h3>

        <div className="space-y-6">
          {currentlyDue.length > 0 && (
            <div className="space-y-4">
              <div className="flex items-center gap-2 text-yellow-600">
                <AlertCircle className="w-5 h-5" />
                <h4 className="font-medium">{t('wallet.required_now')}</h4>
              </div>
              <ul className="space-y-3">
                {currentlyDue.map((requirement) => (
                  <li key={requirement} className="flex items-center gap-3">
                    <div className="w-1.5 h-1.5 bg-yellow-500 rounded-full" />
                    <span className="text-sm text-gray-600">
                      {t(`wallet.requirements.${requirement}`)}
                    </span>
                    {requirement === 'individual.verification.document' && (
                      <input
                        type="file"
                        onChange={handleFileUpload}
                        accept="image/*"
                        className="ml-auto text-sm"
                        disabled={loading}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {eventuallyDue.length > 0 && (
            <div className="space-y-4">
              <div className="flex items-center gap-2 text-gray-600">
                <Clock className="w-5 h-5" />
                <h4 className="font-medium">{t('wallet.required_later')}</h4>
              </div>
              <ul className="space-y-3">
                {eventuallyDue.map((requirement) => (
                  <li key={requirement} className="flex items-center gap-3">
                    <div className="w-1.5 h-1.5 bg-gray-400 rounded-full" />
                    <span className="text-sm text-gray-600">
                      {t(`wallet.requirements.${requirement}`)}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {pendingVerification.length > 0 && (
            <div className="p-4 bg-blue-50 rounded-lg">
              <div className="flex items-start gap-3">
                <Clock className="w-5 h-5 text-blue-500 mt-0.5" />
                <div>
                  <h4 className="font-medium text-blue-900">
                    {t('wallet.verification_pending')}
                  </h4>
                  <p className="text-sm text-blue-700 mt-1">
                    {t('wallet.verification_pending_description')}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}