import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LogIn, User, Menu, X } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { LanguageSelector } from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import { Logo } from './Logo';
import { motion, AnimatePresence } from 'framer-motion';

export function Navbar() {
  const { user, signOut } = useAuth();
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const location = useLocation();

  return (
    <nav className="bg-white shadow-sm sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Logo />
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-6">
            <LanguageSelector />
            {user ? (
              <>
                <Link
                  to="/profile"
                  className="flex items-center space-x-1 text-gray-600 hover:text-indigo-400 transition-colors"
                >
                  <User className="h-5 w-5" />
                  <span>{t('profile')}</span>
                </Link>
                <button
                  onClick={signOut}
                  className="bg-gradient-to-r from-indigo-400 to-purple-400 text-white px-4 py-2 rounded-lg transition-all duration-200 shadow-sm hover:from-indigo-500 hover:to-purple-500 border border-transparent hover:border-indigo-200"
                >
                  {t('logout')}
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="flex items-center space-x-1 text-gray-600 hover:text-indigo-400 transition-colors"
                >
                  <LogIn className="h-5 w-5" />
                  <span>{t('login')}</span>
                </Link>
                <Link
                  to="/signup"
                  className="bg-gradient-to-r from-indigo-400 to-purple-400 text-white px-4 py-2 rounded-lg transition-all duration-200 shadow-sm hover:from-indigo-500 hover:to-purple-500 border border-transparent hover:border-indigo-200"
                >
                  {t('create_account')}
                </Link>
              </>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded-lg text-gray-600 hover:text-indigo-600 hover:bg-indigo-50 transition-colors"
              aria-expanded={isMenuOpen}
              aria-controls="mobile-menu"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            id="mobile-menu"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden border-t border-gray-100"
          >
            <div className="px-4 py-3 space-y-3">
              <div className="flex justify-center">
                <LanguageSelector />
              </div>
              {user ? (
                <>
                  <Link
                    to="/profile"
                    className="flex items-center justify-center space-x-2 p-3 rounded-lg text-gray-600 hover:text-indigo-600 hover:bg-indigo-50 transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <User className="h-5 w-5" />
                    <span>{t('profile')}</span>
                  </Link>
                  <button
                    onClick={() => {
                      signOut();
                      setIsMenuOpen(false);
                    }}
                    className="w-full bg-gradient-to-r from-indigo-400 to-purple-400 text-white p-3 rounded-lg transition-all duration-200 shadow-sm hover:from-indigo-500 hover:to-purple-500"
                  >
                    {t('logout')}
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="flex items-center justify-center space-x-2 p-3 rounded-lg text-gray-600 hover:text-indigo-600 hover:bg-indigo-50 transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <LogIn className="h-5 w-5" />
                    <span>{t('login')}</span>
                  </Link>
                  <Link
                    to="/signup"
                    className="w-full bg-gradient-to-r from-indigo-400 to-purple-400 text-white p-3 rounded-lg text-center transition-all duration-200 shadow-sm hover:from-indigo-500 hover:to-purple-500"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {t('create_account')}
                  </Link>
                </>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}