import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { ModelProfile } from '../types/media';
import { useTranslation } from 'react-i18next';

export function useModels(userId: string | undefined) {
  const [models, setModels] = useState<ModelProfile[]>([]);
  const [selectedModelId, setSelectedModelId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const initializeModels = async () => {
      if (!userId) return;

      try {
        const modelQuery = query(
          collection(db, 'modelProfiles'),
          where('userId', '==', userId)
        );
        const modelSnapshot = await getDocs(modelQuery);
        const modelList = modelSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as ModelProfile[];

        if (modelList.length === 0) {
          const defaultModel = await addDoc(collection(db, 'modelProfiles'), {
            userId,
            name: t('default_model'),
            createdAt: new Date().toISOString()
          });

          const defaultModelData = {
            id: defaultModel.id,
            userId,
            name: t('default_model'),
            createdAt: new Date().toISOString()
          };

          setModels([defaultModelData]);
          setSelectedModelId(defaultModel.id);
        } else {
          setModels(modelList);
          setSelectedModelId(modelList[0].id);
        }
      } catch (error) {
        console.error('Error initializing models:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeModels();
  }, [userId, t]);

  const createModel = async (profileData: Omit<ModelProfile, 'id' | 'userId' | 'createdAt'>) => {
    if (!userId) return null;

    try {
      const newProfile = await addDoc(collection(db, 'modelProfiles'), {
        ...profileData,
        userId,
        createdAt: new Date().toISOString()
      });

      const newModelData = {
        id: newProfile.id,
        ...profileData,
        userId,
        createdAt: new Date().toISOString()
      };

      setModels(prev => [...prev, newModelData]);
      return newModelData;
    } catch (error) {
      console.error('Error creating model:', error);
      return null;
    }
  };

  const deleteModel = async (modelId: string) => {
    try {
      await deleteDoc(doc(db, 'modelProfiles', modelId));
      setModels(prev => prev.filter(model => model.id !== modelId));
      
      if (selectedModelId === modelId) {
        const remainingModels = models.filter(model => model.id !== modelId);
        setSelectedModelId(remainingModels[0]?.id || null);
      }
      
      return true;
    } catch (error) {
      console.error('Error deleting model:', error);
      return false;
    }
  };

  const updateModel = async (modelId: string, updates: Partial<ModelProfile>) => {
    try {
      await updateDoc(doc(db, 'modelProfiles', modelId), updates);
      setModels(prev =>
        prev.map(model =>
          model.id === modelId ? { ...model, ...updates } : model
        )
      );
      return true;
    } catch (error) {
      console.error('Error updating model:', error);
      return false;
    }
  };

  return {
    models,
    selectedModelId,
    setSelectedModelId,
    loading,
    createModel,
    deleteModel,
    updateModel
  };
}