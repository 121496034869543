import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScrollText } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { AuthModal } from '../components/AuthModal';
import { HeroSection } from '../components/home/HeroSection';
import { useTranslation } from 'react-i18next';
import { TermsModal } from '../components/home/TermsModal';
import { motion } from 'framer-motion';

export function Home() {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleStartClick = () => {
    if (user) {
      navigate('/upload');
    } else {
      setShowAuthModal(true);
    }
  };

  const handleLogin = () => {
    setShowAuthModal(false);
    navigate('/login');
  };

  const handleSignup = () => {
    setShowAuthModal(false);
    navigate('/login?signup=true');
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="relative">
        <HeroSection onGetStarted={handleStartClick} />

        <div className="max-w-7xl mx-auto px-4 py-20">
          {/* Terms and Conditions Button */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            className="flex justify-center"
          >
            <button
              onClick={() => setShowTerms(true)}
              className="inline-flex items-center justify-center px-6 py-3 text-gray-600 hover:text-indigo-600 transition-colors text-sm bg-gray-50 rounded-lg hover:bg-gray-100"
            >
              <ScrollText className="w-5 h-5 mr-2" />
              <span>Termes et Conditions</span>
            </button>
          </motion.div>
        </div>

        <AuthModal
          isOpen={showAuthModal}
          onClose={() => setShowAuthModal(false)}
          onLogin={handleLogin}
          onSignup={handleSignup}
        />

        <TermsModal isOpen={showTerms} onClose={() => setShowTerms(false)} />
      </div>
    </div>
  );
}