import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export function Logo() {
  return (
    <Link to="/" className="flex items-center space-x-2 group" aria-label="ClearMeHub">
      <motion.div
        className="relative"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.2 }}
      >
        {/* Logo SVG */}
        <svg
          width="40"
          height="40"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="transform transition-transform group-hover:rotate-12 duration-300"
        >
          {/* Outer hexagon */}
          <path
            d="M60 10L105.98 35V85L60 110L14.0192 85V35L60 10Z"
            fill="url(#gradient-base)"
            className="drop-shadow-lg"
          />
          {/* Inner shape */}
          <path
            d="M60 30L85.98 45V75L60 90L34.0192 75V45L60 30Z"
            fill="white"
            fillOpacity="0.3"
          />
          {/* Center dot */}
          <circle cx="60" cy="60" r="8" fill="white" />
          
          {/* Gradient definitions */}
          <defs>
            <linearGradient
              id="gradient-base"
              x1="0"
              y1="0"
              x2="120"
              y2="120"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0%" stopColor="#4F46E5" />
              <stop offset="100%" stopColor="#7C3AED" />
            </linearGradient>
          </defs>
        </svg>
      </motion.div>

      {/* Text part */}
      <div className="flex flex-col -space-y-1">
        <motion.span
          className="text-xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent"
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          ClearMeHub
        </motion.span>
        <motion.span
          className="text-xs tracking-wider text-gray-500"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          PREMIUM CONTENT
        </motion.span>
      </div>
    </Link>
  );
}