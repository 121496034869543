export const getAuthErrorMessage = (code: string, t: (key: string) => string) => {
  switch (code) {
    case 'auth/email-already-in-use':
      return t('email_already_exists');
    case 'auth/invalid-email':
      return t('invalid_email');
    case 'auth/weak-password':
      return t('weak_password');
    case 'auth/wrong-password':
      return t('wrong_password');
    case 'auth/user-not-found':
      return t('user_not_found');
    case 'auth/operation-not-allowed':
      return t('operation_not_allowed');
    case 'auth/too-many-requests':
      return t('too_many_requests');
    case 'auth/network-request-failed':
      return t('network_error');
    case 'auth/popup-closed-by-user':
      return t('popup_closed');
    case 'auth/unauthorized-domain':
      return t('unauthorized_domain');
    case 'auth/user-disabled':
      return t('user_disabled');
    case 'auth/unknown':
      return t('unknown_error');
    default:
      return t('registration_error');
  }
};