import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { MediaGrid } from '../components/MediaGrid';
import { MediaItem } from '../types/media';
import { useTranslation } from 'react-i18next';
import { ModelProfilesSection } from '../components/ModelProfilesSection';
import { UploadButton } from '../components/UploadButton';
import { ShareLibraryButton } from '../components/ShareLibraryButton';
import { useModels } from '../hooks/useModels';
import { LibraryTabs } from '../components/LibraryTabs';
import { MobileNavigation } from '../components/profile/MobileNavigation';
import { motion, AnimatePresence } from 'framer-motion';

export function Profile() {
  const { user } = useAuth();
  const [userMedia, setUserMedia] = useState<MediaItem[]>([]);
  const { 
    models, 
    selectedModelId, 
    setSelectedModelId, 
    loading,
    createModel,
    deleteModel,
    updateModel
  } = useModels(user?.uid);
  const [activeTab, setActiveTab] = useState<string>('public');
  const { t } = useTranslation();

  const fetchMedia = async () => {
    if (!user || !selectedModelId) return;

    try {
      const mediaQuery = query(
        collection(db, 'media'),
        where('userId', '==', user.uid),
        where('modelId', '==', selectedModelId)
      );
      const mediaSnapshot = await getDocs(mediaQuery);
      const mediaList = mediaSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as MediaItem[];
      setUserMedia(mediaList);
    } catch (error) {
      console.error('Error fetching media:', error);
    }
  };

  useEffect(() => {
    fetchMedia();
  }, [user, selectedModelId]);

  const handleCreateProfile = async (profileData: { name: string }) => {
    if (!createModel) return;
    await createModel(profileData);
  };

  const handleDeleteProfile = async (id: string) => {
    if (!window.confirm(t('confirm_delete_model'))) return;
    await deleteModel(id);
  };

  const handleUpdateProfile = async (id: string, updates: any) => {
    await updateModel(id, updates);
  };

  const handleDeleteMedia = async (id: string) => {
    if (!window.confirm(t('confirm_delete'))) return;

    try {
      await deleteDoc(doc(db, 'media', id));
      setUserMedia(media => media.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting media:', error);
      alert(t('error_deleting'));
    }
  };

  const handleEditMedia = async (id: string, updates: Partial<MediaItem>) => {
    try {
      await updateDoc(doc(db, 'media', id), updates);
      setUserMedia(media =>
        media.map(item =>
          item.id === id ? { ...item, ...updates } : item
        )
      );
    } catch (error) {
      console.error('Error updating media:', error);
      alert(t('error_updating'));
    }
  };

  const handleToggleVisibility = async (id: string) => {
    const media = userMedia.find(m => m.id === id);
    if (!media) return;

    try {
      await updateDoc(doc(db, 'media', id), {
        isPublic: !media.isPublic
      });
      setUserMedia(prev =>
        prev.map(item =>
          item.id === id ? { ...item, isPublic: !item.isPublic } : item
        )
      );
    } catch (error) {
      console.error('Error toggling visibility:', error);
      alert(t('error_updating'));
    }
  };

  const handleUploadComplete = (newMedia: MediaItem) => {
    setUserMedia(prev => [...prev, newMedia]);
  };

  if (!user) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center py-12"
      >
        <p className="text-gray-600">{t('login_required')}</p>
      </motion.div>
    );
  }

  if (loading) {
    return (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex items-center justify-center min-h-[50vh]"
      >
        <div className="relative w-16 h-16">
          <div className="absolute inset-0 rounded-full border-4 border-indigo-100"></div>
          <div className="absolute inset-0 rounded-full border-4 border-indigo-600 border-t-transparent animate-spin"></div>
        </div>
      </motion.div>
    );
  }

  const filteredMedia = userMedia.filter(media => {
    if (activeTab === 'public') return media.isPublic;
    if (activeTab === 'private') return !media.isPublic;
    return true;
  });

  const publicCount = userMedia.filter(media => media.isPublic).length;
  const privateCount = userMedia.filter(media => !media.isPublic).length;

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8 pb-20 portrait:lg:pb-0"
    >
      <motion.div 
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="py-8"
      >
        <ModelProfilesSection
          profiles={models}
          selectedProfileId={selectedModelId}
          onSelectProfile={setSelectedModelId}
          onCreateProfile={handleCreateProfile}
          onUpdateProfile={handleUpdateProfile}
          onDeleteProfile={handleDeleteProfile}
        />
      </motion.div>

      {selectedModelId && (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="flex-1 bg-white rounded-2xl shadow-sm overflow-hidden"
        >
          <div className="p-6">
            <motion.div 
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.6 }}
              className="hidden portrait:lg:flex lg:flex items-center justify-between mb-6"
            >
              <LibraryTabs
                activeTab={activeTab as 'public' | 'private'}
                onTabChange={(tab) => setActiveTab(tab)}
                publicCount={publicCount}
                privateCount={privateCount}
              />
              <div className="flex items-center gap-4">
                {activeTab === 'public' && (
                  <ShareLibraryButton modelId={selectedModelId} />
                )}
                <UploadButton
                  modelId={selectedModelId}
                  onUploadComplete={handleUploadComplete}
                />
              </div>
            </motion.div>

            <AnimatePresence mode="wait">
              {activeTab === 'share' ? (
                <motion.div
                  key="share"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="flex justify-center py-8"
                >
                  <ShareLibraryButton modelId={selectedModelId} />
                </motion.div>
              ) : activeTab === 'upload' ? (
                <motion.div
                  key="upload"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="flex justify-center py-8"
                >
                  <UploadButton
                    modelId={selectedModelId}
                    onUploadComplete={handleUploadComplete}
                  />
                </motion.div>
              ) : filteredMedia.length > 0 ? (
                <motion.div
                  key="grid"
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  transition={{ delay: 0.2 }}
                >
                  <MediaGrid 
                    items={filteredMedia}
                    onPurchase={() => {}}
                    showManageButtons={true}
                    onDelete={handleDeleteMedia}
                    onEdit={handleEditMedia}
                    onToggleVisibility={handleToggleVisibility}
                    isPublicLibrary={activeTab === 'public'}
                  />
                </motion.div>
              ) : (
                <motion.div
                  key="empty"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="text-center py-12"
                >
                  <motion.div 
                    className="mx-auto w-24 h-24 rounded-full bg-gradient-to-br from-indigo-50 to-purple-50 flex items-center justify-center mb-4"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <svg className="w-12 h-12 text-indigo-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </motion.div>
                  <motion.p 
                    className="text-gray-600 text-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    {t('no_media')}
                  </motion.p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      )}

      <MobileNavigation
        activeTab={activeTab}
        onTabChange={setActiveTab}
        publicCount={publicCount}
        privateCount={privateCount}
      />
    </motion.div>
  );
}