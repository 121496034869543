import React from 'react';
import { motion } from 'framer-motion';

interface AnimatedTextProps {
  text: string;
  className?: string;
  delay?: number;
  gradient?: boolean;
  hover?: boolean;
}

export function AnimatedText({ 
  text, 
  className = '', 
  delay = 0,
  gradient = false,
  hover = false
}: AnimatedTextProps) {
  const words = text.split(' ');

  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.12, delayChildren: delay * i },
    }),
  };

  const child = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
    hidden: {
      opacity: 0,
      y: 20,
    },
  };

  const baseClasses = [
    className,
    gradient ? 'bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent' : '',
    hover ? 'transition-all duration-300 hover:bg-gradient-to-r hover:from-indigo-500 hover:to-purple-500 hover:bg-clip-text hover:text-transparent' : '',
  ].filter(Boolean).join(' ');

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="visible"
      className={baseClasses}
    >
      {words.map((word, index) => (
        <motion.span
          variants={child}
          key={index}
          className="inline-block"
          style={{ marginRight: '0.25em' }}
        >
          {word}
        </motion.span>
      ))}
    </motion.div>
  );
}