import React from 'react';
import { MediaItem } from '../../types/media';
import { motion } from 'framer-motion';
import { Eye, Lock, ShoppingCart } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface MediaCardProps {
  media: MediaItem;
  isSelected: boolean;
  onSelect: (id: string) => void;
  onPreview: (media: MediaItem) => void;
}

export function MediaCard({ media, isSelected, onSelect, onPreview }: MediaCardProps) {
  const { t } = useTranslation();
  const handleSelect = () => {
    if (media.blurData?.isBlurred) {
      onSelect(media.id);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`relative group rounded-xl overflow-hidden bg-white shadow-sm hover:shadow-md transition-all ${
        isSelected ? 'ring-2 ring-indigo-500 shadow-lg' : ''
      }`}
    >
      <div className="aspect-square relative">
        {media.type === 'image' ? (
          <img
            src={media.url}
            alt={media.title}
            className={`w-full h-full object-cover ${media.blurData?.isBlurred ? 'blur-lg' : ''}`}
          />
        ) : (
          <video
            src={media.url}
            className={`w-full h-full object-cover ${media.blurData?.isBlurred ? 'blur-lg' : ''}`}
            controls={!media.blurData?.isBlurred}
          />
        )}

        <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center gap-2">
          <motion.button
            onClick={() => onPreview(media)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="p-2 bg-white/90 rounded-full hover:bg-white transition-colors shadow-lg"
          >
            <Eye className="w-4 h-4 text-gray-700" />
          </motion.button>
        </div>

        {media.blurData?.isBlurred && (
          <>
            <div className="absolute top-2 left-2">
              <input
                type="checkbox"
                checked={isSelected}
                onChange={handleSelect}
                className="w-5 h-5 rounded-lg border-2 border-white shadow-md text-indigo-600 focus:ring-indigo-500 focus:ring-offset-2 transition-all cursor-pointer"
              />
            </div>
            <motion.button
              onClick={handleSelect}
              className={`absolute bottom-4 left-1/2 -translate-x-1/2 flex items-center gap-2 px-4 py-2 rounded-lg font-medium transition-all ${
                isSelected
                  ? 'bg-indigo-600 text-white shadow-lg'
                  : 'bg-white/90 text-gray-900 hover:bg-white hover:shadow-lg'
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {isSelected ? (
                <>
                  <ShoppingCart className="w-4 h-4" />
                  <span>{media.price}€</span>
                </>
              ) : (
                <>
                  <Lock className="w-4 h-4" />
                  <span>{t('add_to_cart')}</span>
                </>
              )}
            </motion.button>
          </>
        )}
      </div>

      <div className="p-4">
        <h3 className="font-medium text-gray-900 truncate">{media.title}</h3>
        {media.blurData?.isBlurred && (
          <p className="text-sm font-medium bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
            {media.price}€
          </p>
        )}
      </div>
    </motion.div>
  );
}