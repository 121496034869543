import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { storage, db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { useModels } from '../hooks/useModels';
import { UploadForm } from '../components/UploadForm';
import { useTranslation } from 'react-i18next';

export function Upload() {
  const [uploading, setUploading] = useState(false);
  const { user } = useAuth();
  const { models, selectedModelId, setSelectedModelId, loading } = useModels(user?.uid);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleUpload = async (
    files: File[], 
    price: number, 
    titles: { [key: string]: string }, 
    modelId: string,
    isPublic: boolean
  ) => {
    if (!user || !modelId) return;
    
    setUploading(true);
    try {
      for (const file of files) {
        const storageRef = ref(storage, `media/${user.uid}/${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        
        await addDoc(collection(db, 'media'), {
          userId: user.uid,
          modelId,
          title: titles[file.name] || file.name.replace(/\.[^/.]+$/, ''),
          url,
          price,
          isLocked: true,
          isPublic,
          createdAt: new Date().toISOString()
        });
      }
      navigate('/profile');
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setUploading(false);
    }
  };

  if (!user) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-600">{t('login_required')}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[50vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">{t('upload_title')}</h1>
      {uploading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">{t('uploading')}</p>
        </div>
      ) : (
        <UploadForm 
          onUpload={handleUpload}
          modelProfiles={models}
          selectedModelId={selectedModelId}
          onSelectModel={setSelectedModelId}
        />
      )}
    </div>
  );
}