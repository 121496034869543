import { useState } from 'react';
import { getValidationError } from '../utils/validation';
import { useTranslation } from 'react-i18next';

interface ValidationErrors {
  [key: string]: string | null;
}

export function useFormValidation() {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { t } = useTranslation();

  const validateField = (field: string, value: string) => {
    const error = getValidationError(field, value, t);
    setErrors(prev => ({
      ...prev,
      [field]: error
    }));
    return !error;
  };

  const validateForm = (fields: { [key: string]: string }) => {
    const newErrors: ValidationErrors = {};
    let isValid = true;

    Object.entries(fields).forEach(([field, value]) => {
      const error = getValidationError(field, value, t);
      if (error) {
        isValid = false;
        newErrors[field] = error;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const clearErrors = () => {
    setErrors({});
  };

  return {
    errors,
    validateField,
    validateForm,
    clearErrors
  };
}