import React, { useState } from 'react';
import { X, Share2, Copy, Check, Clock } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

interface ShareLibraryModalProps {
  isOpen: boolean;
  onClose: () => void;
  modelId: string;
  expirationOptions?: { value: number; label: string }[];
}

export function ShareLibraryModal({ 
  isOpen, 
  onClose, 
  modelId,
  expirationOptions = [
    { value: 24, label: '24 heures' },
    { value: 72, label: '3 jours' },
    { value: 168, label: '7 jours' },
    { value: 720, label: '30 jours' },
    { value: -1, label: 'Illimité' }
  ]
}: ShareLibraryModalProps) {
  const [expiration, setExpiration] = useState(24);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  const generateShareLink = () => {
    const baseUrl = window.location.origin;
    const timestamp = Date.now();
    const expirationTime = expiration === -1 ? 'unlimited' : timestamp + (expiration * 60 * 60 * 1000);
    return `${baseUrl}/library/${modelId}?expires=${expirationTime}`;
  };

  const handleCopy = async () => {
    const link = generateShareLink();
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Error copying link:', err);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity"
              onClick={onClose}
            />

            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              className="relative w-full max-w-lg transform overflow-hidden rounded-xl bg-white shadow-xl transition-all"
            >
              {/* Header */}
              <div className="border-b border-gray-200 px-6 py-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Share2 className="h-5 w-5 text-indigo-600" />
                    <h3 className="text-lg font-semibold text-gray-900">
                      {t('share_library')}
                    </h3>
                  </div>
                  <button
                    onClick={onClose}
                    className="rounded-lg p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              </div>

              {/* Content */}
              <div className="p-6 space-y-6">
                {/* Expiration Selection */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {t('link_expiration')}
                  </label>
                  <div className="grid grid-cols-2 gap-3">
                    {expirationOptions.map((option) => (
                      <button
                        key={option.value}
                        onClick={() => setExpiration(option.value)}
                        className={`flex items-center justify-center gap-2 p-3 rounded-lg border-2 transition-all ${
                          expiration === option.value
                            ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                            : 'border-gray-200 hover:border-indigo-200 text-gray-600'
                        }`}
                      >
                        <Clock className="w-4 h-4" />
                        <span>{option.label}</span>
                      </button>
                    ))}
                  </div>
                </div>

                {/* Share Link */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {t('share_link')}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="relative flex flex-grow items-stretch">
                      <input
                        type="text"
                        value={generateShareLink()}
                        readOnly
                        className="block w-full rounded-l-lg border-gray-300 bg-gray-50 pr-12 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <button
                        onClick={handleCopy}
                        className="absolute inset-y-0 right-0 flex items-center px-3 hover:text-indigo-600"
                      >
                        {copied ? (
                          <Check className="h-4 w-4 text-green-600" />
                        ) : (
                          <Copy className="h-4 w-4" />
                        )}
                      </button>
                    </div>
                    <button
                      onClick={handleCopy}
                      className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-lg px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      {copied ? t('copied') : t('copy')}
                    </button>
                  </div>
                </div>

                {/* Instructions */}
                <div className="rounded-lg bg-gray-50 p-4">
                  <div className="text-sm text-gray-600">
                    <p className="font-medium mb-2">{t('share_instructions')}</p>
                    <ul className="list-disc pl-5 space-y-1">
                      <li>{t('share_instruction_1')}</li>
                      <li>{t('share_instruction_2')}</li>
                      <li>{t('share_instruction_3')}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
}