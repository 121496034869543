import { useState } from 'react';
import { useAuth } from './useAuth';
import { useStripeAccount } from './useStripeAccount';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { validateIBAN } from '../utils/validation/iban';
import { validateDateOfBirth } from '../utils/validation/date';
import { validatePhoneNumber } from '../utils/validation/phone';

export function useWalletSetup() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: { day: '', month: '', year: '' },
    address: {
      line1: '',
      city: '',
      postal_code: '',
      country: 'FR'
    },
    phone: '',
    iban: ''
  });

  const [submitting, setSubmitting] = useState(false);
  const { user } = useAuth();
  const { createAccount } = useStripeAccount();

  const handleSubmit = async (): Promise<{ success: boolean; error?: string }> => {
    if (!user?.email || submitting) {
      return { success: false, error: 'Invalid state' };
    }

    setSubmitting(true);

    try {
      const accountData = {
        email: user.email,
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        dob: {
          day: parseInt(formData.dob.day),
          month: parseInt(formData.dob.month),
          year: parseInt(formData.dob.year)
        },
        address: formData.address,
        phone: formData.phone,
        iban: formData.iban.replace(/\s/g, '')
      };

      console.log('Submitting account data:', {
        ...accountData,
        iban: 'XXXX',
        email: '****@****.***'
      });

      const accountId = await createAccount(accountData);

      if (!accountId) {
        throw new Error('Failed to create Stripe account');
      }

      await updateDoc(doc(db, 'users', user.uid), {
        stripeAccountId: accountId,
        stripeOnboardingCompleted: true,
        firstName: accountData.firstName,
        lastName: accountData.lastName,
        updatedAt: new Date().toISOString()
      });

      return { success: true };
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      return {
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error occurred'
      };
    } finally {
      setSubmitting(false);
    }
  };

  return {
    formData,
    setFormData,
    submitting,
    handleSubmit
  };
}