import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { AlertCircle, RefreshCcw } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="rounded-lg bg-red-50 p-6"
    >
      <div className="flex items-start gap-4">
        <AlertCircle className="w-6 h-6 text-red-500 mt-1" />
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-red-800">
            {t('wallet.error_occurred')}
          </h3>
          <p className="mt-2 text-sm text-red-700">
            {error.message}
          </p>
          <button
            onClick={resetErrorBoundary}
            className="mt-4 inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-red-600 bg-red-100 rounded-lg hover:bg-red-200 transition-colors"
          >
            <RefreshCcw className="w-4 h-4" />
            {t('wallet.try_again')}
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export function WalletErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ReactErrorBoundary>
  );
}