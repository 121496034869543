import React, { useState, useRef } from 'react';
import { Upload, Globe, Lock } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ModelProfile } from '../types/media';

interface UploadFormProps {
  onUpload: (files: File[], price: number, titles: { [key: string]: string }, modelId: string, isPublic: boolean) => void;
  modelProfiles: ModelProfile[];
  selectedModelId: string | null;
  onSelectModel: (id: string) => void;
}

interface FileWithPreview extends File {
  preview?: string;
}

export function UploadForm({ onUpload, modelProfiles, selectedModelId, onSelectModel }: UploadFormProps) {
  const [price, setPrice] = useState<number>(5.99);
  const [selectedFiles, setSelectedFiles] = useState<FileWithPreview[]>([]);
  const [titles, setTitles] = useState<{ [key: string]: string }>({});
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    const filesWithPreviews = Array.from(files).map(file => {
      const preview = URL.createObjectURL(file);
      setTitles(prev => ({
        ...prev,
        [file.name]: file.name.replace(/\.[^/.]+$/, '')
      }));
      return Object.assign(file, { preview });
    });
    setSelectedFiles(filesWithPreviews);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedFiles.length > 0 && selectedModelId) {
      onUpload(selectedFiles, price, titles, selectedModelId, isPublic);
    }
  };

  const handleTitleChange = (fileName: string, newTitle: string) => {
    setTitles(prev => ({
      ...prev,
      [fileName]: newTitle
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto space-y-8">
      {/* Model Selection */}
      <div className="bg-white/60 backdrop-blur-sm p-6 rounded-lg shadow-sm">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {t('select_model')}
        </label>
        <select
          value={selectedModelId || ''}
          onChange={(e) => onSelectModel(e.target.value)}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-white/90"
          required
        >
          <option value="">{t('choose_model')}</option>
          {modelProfiles.map((profile) => (
            <option key={profile.id} value={profile.id}>
              {profile.name}
            </option>
          ))}
        </select>
      </div>

      {/* Library Type Selection */}
      <div className="bg-white/60 backdrop-blur-sm p-6 rounded-lg shadow-sm">
        <label className="block text-sm font-medium text-gray-700 mb-4">
          {t('select_library')}
        </label>
        <div className="flex gap-4">
          <button
            type="button"
            onClick={() => setIsPublic(true)}
            className={`flex-1 flex items-center justify-center gap-2 p-4 rounded-lg border-2 transition-all ${
              isPublic
                ? 'border-indigo-500 bg-indigo-50/50 text-indigo-700'
                : 'border-gray-200 hover:border-indigo-200 text-gray-600'
            }`}
          >
            <Globe className="w-5 h-5" />
            <span className="font-medium">{t('public_library')}</span>
          </button>
          <button
            type="button"
            onClick={() => setIsPublic(false)}
            className={`flex-1 flex items-center justify-center gap-2 p-4 rounded-lg border-2 transition-all ${
              !isPublic
                ? 'border-indigo-500 bg-indigo-50/50 text-indigo-700'
                : 'border-gray-200 hover:border-indigo-200 text-gray-600'
            }`}
          >
            <Lock className="w-5 h-5" />
            <span className="font-medium">{t('private_library')}</span>
          </button>
        </div>
      </div>

      {/* File Upload */}
      <div
        onClick={() => fileInputRef.current?.click()}
        className="border-2 border-dashed rounded-lg p-12 text-center cursor-pointer transition-colors hover:border-indigo-400 bg-white/60 backdrop-blur-sm"
      >
        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          onChange={handleFileSelect}
          accept="image/*,video/*"
          multiple
        />
        <Upload className="mx-auto h-12 w-12 text-gray-400" />
        <p className="mt-2 text-sm text-gray-600">
          {t('upload_subtitle')}
        </p>
        <p className="mt-1 text-xs text-gray-500">
          {t('supported_formats')}
        </p>
      </div>

      {selectedFiles.length > 0 && (
        <div className="bg-white/60 backdrop-blur-sm p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            {t('media_settings')}
          </h3>
          
          <div className="space-y-6">
            {selectedFiles.map((file, index) => (
              <div key={index} className="flex gap-4 items-start p-4 bg-gray-50/80 rounded-lg">
                {file.type.startsWith('image/') && file.preview && (
                  <img
                    src={file.preview}
                    alt={titles[file.name]}
                    className="w-20 h-20 object-cover rounded-lg"
                  />
                )}
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('media_title')}
                  </label>
                  <input
                    type="text"
                    value={titles[file.name]}
                    onChange={(e) => handleTitleChange(file.name, e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm bg-white/90"
                    placeholder={t('enter_title')}
                  />
                  <p className="mt-1 text-xs text-gray-500">{file.name}</p>
                </div>
              </div>
            ))}

            <div>
              <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                {t('price_label')}
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">€</span>
                </div>
                <input
                  type="number"
                  name="price"
                  id="price"
                  min="0.99"
                  step="0.01"
                  value={price}
                  onChange={(e) => setPrice(Number(e.target.value))}
                  className="block w-full pl-7 pr-12 py-2 rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 bg-white/90"
                  placeholder="0.00"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">EUR</span>
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            disabled={!selectedModelId}
            className={`mt-6 w-full px-4 py-2 rounded-lg transition-colors ${
              selectedModelId
                ? 'bg-indigo-600/90 text-white hover:bg-indigo-700/90'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            {t('upload_button')}
          </button>
        </div>
      )}
    </form>
  );
}