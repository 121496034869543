import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { CreditCard, AlertCircle } from 'lucide-react';

interface OnboardingFormProps {
  formData: {
    firstName: string;
    lastName: string;
    dob: {
      day: string;
      month: string;
      year: string;
    };
    address: {
      line1: string;
      city: string;
      postal_code: string;
      country: string;
    };
    phone: string;
    iban: string;
  };
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  submitting: boolean;
  onSubmit: (e: React.FormEvent) => void;
}

export function OnboardingForm({
  formData,
  setFormData,
  submitting,
  onSubmit
}: OnboardingFormProps) {
  const { t } = useTranslation();

  const handleChange = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAddressChange = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      address: {
        ...prev.address,
        [field]: value
      }
    }));
  };

  const handleDOBChange = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      dob: {
        ...prev.dob,
        [field]: value
      }
    }));
  };

  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t('wallet.first_name')}
          </label>
          <input
            type="text"
            value={formData.firstName}
            onChange={(e) => handleChange('firstName', e.target.value)}
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t('wallet.last_name')}
          </label>
          <input
            type="text"
            value={formData.lastName}
            onChange={(e) => handleChange('lastName', e.target.value)}
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          {t('wallet.date_of_birth')}
        </label>
        <div className="grid grid-cols-3 gap-4 mt-1">
          <div>
            <select
              value={formData.dob.day}
              onChange={(e) => handleDOBChange('day', e.target.value)}
              className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            >
              <option value="">{t('wallet.day')}</option>
              {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
                <option key={day} value={day}>{day}</option>
              ))}
            </select>
          </div>
          <div>
            <select
              value={formData.dob.month}
              onChange={(e) => handleDOBChange('month', e.target.value)}
              className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            >
              <option value="">{t('wallet.month')}</option>
              {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                <option key={month} value={month}>{month}</option>
              ))}
            </select>
          </div>
          <div>
            <select
              value={formData.dob.year}
              onChange={(e) => handleDOBChange('year', e.target.value)}
              className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            >
              <option value="">{t('wallet.year')}</option>
              {Array.from(
                { length: 100 },
                (_, i) => new Date().getFullYear() - i
              ).map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          {t('wallet.address')}
        </label>
        <input
          type="text"
          value={formData.address.line1}
          onChange={(e) => handleAddressChange('line1', e.target.value)}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          required
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t('wallet.city')}
          </label>
          <input
            type="text"
            value={formData.address.city}
            onChange={(e) => handleAddressChange('city', e.target.value)}
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t('wallet.postal_code')}
          </label>
          <input
            type="text"
            value={formData.address.postal_code}
            onChange={(e) => handleAddressChange('postal_code', e.target.value)}
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          {t('wallet.country')}
        </label>
        <select
          value={formData.address.country}
          onChange={(e) => handleAddressChange('country', e.target.value)}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          required
        >
          <option value="">{t('wallet.select_country')}</option>
          <option value="FR">France</option>
          <option value="DE">Germany</option>
          <option value="IT">Italy</option>
          <option value="ES">Spain</option>
          <option value="GB">United Kingdom</option>
          <option value="BE">Belgium</option>
          <option value="NL">Netherlands</option>
          <option value="PT">Portugal</option>
          <option value="AT">Austria</option>
          <option value="IE">Ireland</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          {t('wallet.phone')}
        </label>
        <input
          type="tel"
          value={formData.phone}
          onChange={(e) => handleChange('phone', e.target.value)}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="+33612345678"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          {t('wallet.iban')}
        </label>
        <input
          type="text"
          value={formData.iban}
          onChange={(e) => handleChange('iban', e.target.value)}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="FR76 XXXX XXXX XXXX XXXX XXXX XXX"
          required
        />
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="submit"
          disabled={submitting}
          className="inline-flex items-center justify-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
        >
          {submitting ? (
            <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
          ) : (
            <>
              <CreditCard className="w-5 h-5" />
              <span>{t('wallet.setup_account')}</span>
            </>
          )}
        </button>
      </div>
    </form>
  );
}