import React from 'react';
import { User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ValidationMessage } from './ValidationMessage';
import { useFormValidation } from '../../hooks/useFormValidation';

interface SignupFormProps {
  onSubmit: (data: { pseudo: string; email: string; password: string; }) => void;
  loading: boolean;
}

export function SignupForm({ onSubmit, loading }: SignupFormProps) {
  const { t } = useTranslation();
  const { errors, validateField, validateForm, clearErrors } = useFormValidation();
  const [formData, setFormData] = React.useState({
    pseudo: '',
    email: '',
    password: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    clearErrors();

    if (validateForm(formData)) {
      onSubmit(formData);
    }
  };

  const handleChange = (field: keyof typeof formData) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setFormData(prev => ({ ...prev, [field]: value }));
    validateField(field, value);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="pseudo" className="block text-sm font-medium text-gray-700">
          {t('pseudo')}
        </label>
        <div className="mt-1 relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
            <User className="h-5 w-5 text-gray-400" />
          </div>
          <input
            id="pseudo"
            type="text"
            value={formData.pseudo}
            onChange={handleChange('pseudo')}
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
            required
            minLength={3}
            maxLength={30}
          />
        </div>
        {errors.pseudo && <ValidationMessage message={errors.pseudo} type="error" />}
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          {t('email')}
        </label>
        <input
          id="email"
          type="email"
          value={formData.email}
          onChange={handleChange('email')}
          className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
        {errors.email && <ValidationMessage message={errors.email} type="error" />}
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
          {t('password')}
        </label>
        <input
          id="password"
          type="password"
          value={formData.password}
          onChange={handleChange('password')}
          className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          required
          minLength={8}
        />
        {errors.password && <ValidationMessage message={errors.password} type="error" />}
      </div>

      <button
        type="submit"
        disabled={loading}
        className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
      >
        {loading ? (
          <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
        ) : (
          t('create_account')
        )}
      </button>
    </form>
  );
}