import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { MediaItem, ModelProfile } from '../types/media';
import { useTranslation } from 'react-i18next';
import { Logo } from '../components/Logo';
import { MediaCard } from '../components/shared-library/MediaCard';
import { CartSummary } from '../components/shared-library/CartSummary';
import { PreviewModal } from '../components/gallery/PreviewModal';

export function SharedLibrary() {
  const { modelId } = useParams();
  const [searchParams] = useSearchParams();
  const [media, setMedia] = useState<MediaItem[]>([]);
  const [model, setModel] = useState<ModelProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedMediaIds, setSelectedMediaIds] = useState<Set<string>>(new Set());
  const [previewItem, setPreviewItem] = useState<MediaItem | null>(null);
  const { t } = useTranslation();

  const expires = searchParams.get('expires');
  const hasExpired = expires && expires !== 'unlimited' && parseInt(expires) < Date.now();

  useEffect(() => {
    const fetchLibrary = async () => {
      if (!modelId || hasExpired) return;

      try {
        const modelDoc = await getDoc(doc(db, 'modelProfiles', modelId));
        if (modelDoc.exists()) {
          setModel({ id: modelDoc.id, ...modelDoc.data() } as ModelProfile);
        }

        const mediaQuery = query(
          collection(db, 'media'),
          where('modelId', '==', modelId),
          where('isPublic', '==', true)
        );
        const mediaSnapshot = await getDocs(mediaQuery);
        const mediaList = mediaSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as MediaItem[];
        setMedia(mediaList);
      } catch (error) {
        console.error('Error fetching library:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLibrary();
  }, [modelId, hasExpired]);

  const toggleMediaSelection = (id: string) => {
    setSelectedMediaIds(prev => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  const handleCheckout = () => {
    const selectedMedia = media.filter(item => selectedMediaIds.has(item.id));
    // Implement checkout logic
  };

  if (hasExpired) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            {t('link_expired')}
          </h1>
          <p className="text-gray-600">
            {t('link_expired_message')}
          </p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!model) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            {t('library_not_found')}
          </h1>
          <p className="text-gray-600">
            {t('library_not_available')}
          </p>
        </div>
      </div>
    );
  }

  const selectedMedia = media.filter(item => selectedMediaIds.has(item.id));

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-12">
          <Logo />
          <h1 className="text-2xl font-bold text-gray-900">
            {model.name}
          </h1>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {media.map(item => (
            <MediaCard
              key={item.id}
              media={item}
              isSelected={selectedMediaIds.has(item.id)}
              onSelect={toggleMediaSelection}
              onPreview={setPreviewItem}
            />
          ))}
        </div>

        {media.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-500">{t('no_media_found')}</p>
          </div>
        )}

        <CartSummary
          selectedMedia={selectedMedia}
          onCheckout={handleCheckout}
        />

        <PreviewModal
          isOpen={!!previewItem}
          onClose={() => setPreviewItem(null)}
          media={previewItem}
        />
      </div>
    </div>
  );
}