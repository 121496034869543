import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useStripeAccount } from './useStripeAccount';
import { AccountStatus } from '../types/stripe';
import { useAuth } from './useAuth';

export function useWalletData() {
  const { user } = useAuth();
  const { getAccountRequirements } = useStripeAccount();
  const [stripeAccountId, setStripeAccountId] = useState<string | null>(null);
  const [accountStatus, setAccountStatus] = useState<AccountStatus | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching user document...');
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        
        if (!userDoc.exists()) {
          throw new Error('User document not found');
        }

        const data = userDoc.data();
        console.log('User document data:', data);

        if (!data.stripeAccountId) {
          setLoading(false);
          return;
        }

        setStripeAccountId(data.stripeAccountId);
        console.log('Fetching Stripe account requirements...');
        const requirements = await getAccountRequirements(data.stripeAccountId);
        setAccountStatus(requirements);
      } catch (err) {
        console.error('Error fetching wallet data:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch wallet data'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, getAccountRequirements]);

  return {
    stripeAccountId,
    accountStatus,
    loading,
    error
  };
}