import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { WalletOverview } from '../components/wallet/WalletOverview';
import { WalletSetupForm } from '../components/wallet/WalletSetupForm';
import { AccountRequirements } from '../components/wallet/AccountRequirements';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { WalletErrorBoundary } from '../components/wallet/ErrorBoundary';
import { LoadingState } from '../components/wallet/LoadingState';
import { useWalletData } from '../hooks/useWalletData';

export function Wallet() {
  const { user } = useAuth();
  const { stripeAccountId, accountStatus, loading, error } = useWalletData();
  const { t } = useTranslation();

  if (!user) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center py-12"
      >
        <p className="text-gray-600">{t('login_required')}</p>
      </motion.div>
    );
  }

  return (
    <WalletErrorBoundary>
      <div className="max-w-4xl mx-auto px-4 py-8 space-y-8">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl font-bold text-gray-900"
        >
          {t('wallet.title')}
        </motion.h1>

        {loading ? (
          <LoadingState />
        ) : error ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-red-50 rounded-lg p-4"
          >
            <p className="text-red-700">{error.message}</p>
          </motion.div>
        ) : stripeAccountId && accountStatus ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-8"
          >
            <WalletOverview 
              accountStatus={accountStatus}
              stripeAccountId={stripeAccountId}
            />
            <AccountRequirements 
              requirements={accountStatus.requirements}
              stripeAccountId={stripeAccountId}
            />
          </motion.div>
        ) : (
          <WalletSetupForm />
        )}
      </div>
    </WalletErrorBoundary>
  );
}