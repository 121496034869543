import axios from 'axios';
import type { CreateAccountData } from './types';
import { STRIPE_CONFIG } from './config';

export class StripeApiError extends Error {
  constructor(
    message: string,
    public code?: string,
    public statusCode?: number,
    public raw?: any
  ) {
    super(message);
    this.name = 'StripeApiError';
  }
}

export const stripeApi = {
  async createAccount(data: CreateAccountData): Promise<string> {
    try {
      console.log('Creating Stripe account:', {
        ...data,
        iban: 'XXXX',
        email: '****@****.***'
      });

      const response = await axios.post('/api/create-account', data, {
        headers: {
          'Content-Type': 'application/json'
        },
        validateStatus: (status) => status < 500
      });

      if (response.status !== 200 || !response.data?.accountId) {
        console.error('Stripe API error:', response.data);
        throw new StripeApiError(
          response.data?.error || 'Failed to create Stripe account',
          response.data?.code,
          response.status
        );
      }

      console.log('Stripe account created successfully:', response.data.accountId);
      return response.data.accountId;

    } catch (err) {
      console.error('Stripe API error:', err);

      if (axios.isAxiosError(err)) {
        const errorData = err.response?.data;
        throw new StripeApiError(
          errorData?.error || 'Failed to create Stripe account',
          errorData?.code,
          err.response?.status,
          err
        );
      }

      throw new StripeApiError(
        err instanceof Error ? err.message : 'An unexpected error occurred'
      );
    }
  }
};