import React, { useState } from 'react';
import { Share2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ShareLibraryModal } from './ShareLibraryModal';

interface ShareLibraryButtonProps {
  modelId: string;
  className?: string;
}

export function ShareLibraryButton({ modelId, className = '' }: ShareLibraryButtonProps) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className={`flex items-center space-x-2 px-4 py-2 rounded-lg bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent hover:from-indigo-700 hover:to-purple-700 transition-all duration-300 ${className}`}
      >
        <Share2 className="w-4 h-4 text-indigo-600" />
        <span className="font-medium">{t('share_library')}</span>
      </button>

      <ShareLibraryModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        modelId={modelId}
      />
    </>
  );
}